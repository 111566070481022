import {  ADD_ACTIVITY_ENDPOINT } from './../../Config/Constants';
import { axiosInstance } from "../../Config/api"


export const addActivityApi:any = async (params:any) => {

    try {
        var resp = await axiosInstance.post(ADD_ACTIVITY_ENDPOINT,params)
      
        return resp;
    } catch (error) {
        return error;
    }

}

