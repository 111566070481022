export const CHECK_AUTH = 'CHECK_AUTH'
export const CHECK_IS_LOGEDIN = 'CHECK_IS_LOGEDIN'
export const SUCESS_CHECK_AUTH = 'SUCESS_CHECK_AUTH'

export const LOGIN = 'LOGIN'
export const LOG_OUT = 'LOG_OUT'

export const LOGIN_START = 'LOGIN_START'

export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const FETCH_FLIGHT = 'FETCH_FLIGHT'
export const FETCH_FLIGHT_START = 'FETCH_FLIGHT_START'
export const FETCH_FLIGHT_SUCCESS = 'FETCH_FLIGHT_SUCCESS'
export const FETCH_FLIGHT_FAIL = 'FETCH_FLIGHT_FAIL'

export const BOOK_FLIGHT = 'BOOK_FLIGHT'
export const BOOK_FLIGHT_START = 'BOOK_FLIGHT_START'
export const BOOK_FLIGHT_SUCCESS = 'BOOK_FLIGHT_SUCCESS'
export const BOOK_FLIGHT_FAIL = 'BOOK_FLIGHT_FAIL'

export const BOOK_RESET = 'BOOK_RESET'

// add new kashaf types
export const ADD_KASHAF = 'ADD_KASHAF'
export const ADD_KASHAF_OFFLINE = 'ADD_KASHAF_OFFLINE'

export const ADD_KASHAF_RESET = 'ADD_KASHAF_RESET'

export const ADD_KASHAF_START = 'ADD_KASHAF_START'
export const ADD_KASHAF_SUCCESS = 'ADD_KASHAF_SUCCESS'
export const ADD_KASHAF_FAIL = 'ADD_KASHAF_FAIL'

// add new jalasat
export const ADD_JALASA = 'ADD_JALASA'
export const ADD_JALASA_START = 'ADD_JALASA_START'
export const ADD_JALASA_SUCCESS = 'ADD_JALASA_SUCCESS'
export const ADD_JALASA_FAIL = 'ADD_JALASA_FAIL'
export const ADD_JALASA_RESET = 'ADD_JALASA_RESET'

// add new visits
export const ADD_VISIT = 'ADD_VISIT'
export const ADD_VISIT_START = 'ADD_VISIT_START'
export const ADD_VISIT_SUCCESS = 'ADD_VISIT_SUCCESS'
export const ADD_VISIT_FAIL = 'ADD_VISIT_FAIL'
export const ADD_VISIT_RESET = 'ADD_VISIT_RESET'

// add new activity
export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const ADD_ACTIVITY_START = 'ADD_ACTIVITY_START'
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS'
export const ADD_ACTIVITY_FAIL = 'ADD_ACTIVITY_FAIL'
export const ADD_ACTIVITY_RESET = 'ADD_ACTIVITY_RESET'






// getting new actions
export const GET_MUHAFAZAT ='GET_MUHAFAZAT'
export const GET_MUHAFAZAT_SUCCESS ='GET_MUHAFAZAT_SUCCESS'
export const GET_MUHAFAZAT_START ='GET_MUHAFAZAT_START'
export const GET_MUHAFAZAT_FAIL ='GET_MUHAFAZAT_FAIL'
export const GET_MUHAFAZAT_RESET ='GET_MUHAFAZAT_RESET'
export const GET_MUHAFAZAT_SELECT='GET_MUHAFAZAT_SELECT'



// new getting manaik
export const GET_MANATIK ='GET_MANATIK'
export const GET_MANATIK_SUCCESS ='GET_MANATIK_SUCCESS'
export const GET_MANATIK_START ='GET_MANATIK_START'
export const GET_MANATIK_FAIL ='GET_MANATIK_FAIL'
export const GET_MANATIK_RESET ='GET_MANATIK_RESET'


// new actions for importing faraq
export const GET_FARAQ ='GET_FARAQ'
export const GET_FARAQ_SUCCESS ='GET_FARAQ_SUCCESS'
export const GET_FARAQ_START ='GET_FARAQ_START'
export const GET_FARAQ_FAIL ='GET_FARAQ_FAIL'
export const GET_FARAQ_RESET ='GET_FARAQ_RESET'


// new histroy
export const GET_HISTORY ='GET_HISTORY'
export const GET_HISTORY_SUCCESS ='GET_HISTORY_SUCCESS'
export const GET_HISTORY_START ='GET_HISTORY_START'
export const GET_HISTORY_FAIL ='GET_HISTORY_FAIL'
export const GET_HISTORY_RESET ='GET_HISTORY_RESET'


