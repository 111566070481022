import { ADD_ACTIVITY_START, ADD_ACTIVITY_SUCCESS, ADD_ACTIVITY_FAIL, ADD_ACTIVITY_RESET } from './../global/actionTypes';
import produce, { Draft } from 'immer';
import {  IActivity } from './types';

const initialState: IActivity = {
    data:null,
    loading:false,
    error:null,

  };



  const addActivityStartReducers = (state:IActivity)=> {

    return  produce(state,(draft:Draft<IActivity>) => {
          draft.data=null;
          draft.error = null;
          draft.loading = true
      
      })
  
}



const addActivitySuccessReducers = (state:IActivity,action:any)=> {

  return  produce(state,(draft:Draft<IActivity>) => {
        draft.error = null;
        draft.loading = false;
        draft.data = action.payload
    
    })

}

const addActivityFailReducers = (state:IActivity,action:any)=> {

  return  produce(state,(draft:Draft<IActivity>) => {
        draft.error = action.error
        draft.loading = false;
    
    })

}

const addActivityResetReducers = (state:IActivity)=> {

  return  produce(state,(draft:Draft<IActivity>) => {
        draft.error = null
        draft.loading = false;
        draft.data = null
    
    })

}


 function ActivityReducer(state = initialState, action: any) {
    switch (action.type) {
     case ADD_ACTIVITY_START : return addActivityStartReducers(state);
     case ADD_ACTIVITY_SUCCESS: return addActivitySuccessReducers(state,action);
     case ADD_ACTIVITY_FAIL: return addActivityFailReducers(state,action)
     case ADD_ACTIVITY_RESET: return addActivityResetReducers(state)

      default: return state
    }
  };
  

  export default ActivityReducer

