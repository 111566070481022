import { getFaraqFailReducers, getFaraqResetReducers, getFaraqStartReducers, getFaraqSuccessReducers, getManatikFailReducers, getManatikResetReducers, getManatikStartReducers, getManatikSuccessReducers, getMuhafazatFailReducers, getMuhafazatResetReducers, getMuhafazatSelectIdStartReducers, getMuhafazatStartReducers, getMuhafazatSuccessReducers } from './../Estmara/reducers';
import  produce, { Draft } from "immer";
import { GET_FARAQ_FAIL, GET_FARAQ_RESET, GET_FARAQ_START, GET_FARAQ_SUCCESS, GET_MANATIK_FAIL, GET_MANATIK_RESET, GET_MANATIK_START, GET_MANATIK_SUCCESS, GET_MUHAFAZAT_FAIL, GET_MUHAFAZAT_RESET, GET_MUHAFAZAT_SELECT, GET_MUHAFAZAT_START, GET_MUHAFAZAT_SUCCESS, LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS, SUCESS_CHECK_AUTH } from "../global/actionTypes";
import { IGeneral } from "./types";
const initialState: IGeneral = {
  token: null,
  loading: false,
  error: null,
  isAuth: false,
  Muhafazat: {
    data: undefined,
    loading: false,
    error: null,
    selectedId:null
  },
  Manatiq: {
    data: undefined,
    loading: false,
    error: null
  },
  Faraq: {
    data: undefined,
    loading: false,
    error: null
  }
};


  const loginStartReducer = (state:IGeneral)=> {

      return  produce(state,(draft:Draft<IGeneral>) => {
            draft.error = null;
            draft.loading = true
        
        })
    
  }

  const loginFailReducer = (state:IGeneral,action:any)=> {
    return produce(state,(draft:Draft<IGeneral>) => {
        draft.error = action.error;
        draft.loading = false
    })

}

const loginSuccessReducer = (state:IGeneral,action:any) => {
    return produce(state,(draft:Draft<IGeneral>) => {
        draft.error = null;
        draft.loading = false
        draft.token = action.payload
        draft.isAuth=true;
    })

}

const authSucessReducers = (state:IGeneral,action:any) => {
  return produce(state,(draft:Draft<IGeneral>) => {
   
      draft.isAuth=action.payload;
  })

}




function GeneralResucers(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN_START     : return loginStartReducer(state);
    case LOGIN_FAIL      : return loginFailReducer(state,action);
    case LOGIN_SUCCESS   : return loginSuccessReducer(state,action);
    case SUCESS_CHECK_AUTH : return authSucessReducers(state,action);
    case GET_MUHAFAZAT_START : return getMuhafazatStartReducers(state);
    case GET_MUHAFAZAT_SUCCESS: return getMuhafazatSuccessReducers(state,action);
    case GET_MUHAFAZAT_FAIL: return getMuhafazatFailReducers(state,action)
    case GET_MUHAFAZAT_RESET: return getMuhafazatResetReducers(state)
    case GET_MANATIK_START : return getManatikStartReducers(state);
    case GET_MANATIK_SUCCESS: return getManatikSuccessReducers(state,action);
    case GET_MANATIK_FAIL: return getManatikFailReducers(state,action)
    case GET_MANATIK_RESET: return getManatikResetReducers(state)
    case GET_FARAQ_START : return getFaraqStartReducers(state);
    case GET_FARAQ_SUCCESS: return getFaraqSuccessReducers(state,action);
    case GET_FARAQ_FAIL: return getFaraqFailReducers(state,action)
    case GET_FARAQ_RESET: return getFaraqResetReducers(state);
    case GET_MUHAFAZAT_SELECT: return getMuhafazatSelectIdStartReducers(state,action)
    default: return state
  }
};


export default GeneralResucers
