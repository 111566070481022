import React, { createContext, useEffect, useState } from "react";
import { FC } from "react";
import MainHeader from "../Headers/MainHeader/Index";
import { Props } from "./types";

import { Modal } from "antd";
import { SUCCESS_MSG } from "../../../Common/Constants";
import { IVoidFunctionType } from "../../../Common/types";
const info = (message: string) => {
  Modal.info({
    title: "اشعار ",
    content: (
      <div>
        <p>{message}</p>
      </div>
    ),
    onOk() {},
  });
};

const successMe = (msg: string = SUCCESS_MSG) => {
  Modal.success({
    content: msg,
  });
};

const errorMe = (msg: string) => {
  Modal.error({
    title: "شنو صار !",
    content: msg,
  });
};

const warning = (msg: string) => {
  Modal.warning({
    title: "انذار !",
    content: msg,
  });
};

const MobileLayout: FC<Props> = ({
  success,
  children,
  title,
  back,
  error,
  message,
  letfIcon,
  onSuccess,
}) => {
  const _handleOk = (e: any) => {};
  useEffect(() => {
    if (error) {
      errorMe(error);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      successMe(SUCCESS_MSG);
      if (onSuccess) onSuccess();
    }
  }, [success]);

  return (
    <div>
      <MainHeader 
        title={title} 
        back={back} 
        letfIcon={letfIcon}
         />
      {children}
    </div>
  );
};

export default MobileLayout;
