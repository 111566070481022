import React from "react";
import { FC } from "react";
import { ContainerStyled, ErrorStyled } from "./styled";
import { Props } from "./types";
import { DatePicker, Form, Input, InputNumber, List } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { DATE_FORMAT } from "../../../Common/Constants";
const TextField: FC<Props> = ({
  label,
  placeholder,
  value,
  name,
  type,
  rules,
  hidden,
  points,
  onEnter,
}) => {
  const Component: any = {
    text: <Input placeholder={placeholder} hidden={hidden} />,
    email: <Input placeholder={placeholder} type={type} />,
    password: (
      <Input.Password
        placeholder={placeholder}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
    ),
    date: (
      <DatePicker
        defaultValue={moment(moment(), DATE_FORMAT)}
        placeholder={placeholder}
        /// format={"MMMM Do YYYY"}
      />
    ),
    number: <InputNumber placeholder={placeholder} />,
    area: (
      <TextArea
        placeholder={placeholder}
        autoSize={{ minRows: 3, maxRows: 12 }}
        onKeyUp={(e: any) => {
          if (onEnter) onEnter(e.target.value.trim(), name);
        }}
      />
    ),
  };
  return (
    <ContainerStyled className={`field `}>
      {!hidden && (
        <Form.Item name={name} label={label} rules={rules} labelAlign="right">
          {Component[type]}
        </Form.Item>
      )}
      {Boolean(points && points.length > 0) && (
        <List
          size="small"
          header={<h5>{label}</h5>}
          bordered
          dataSource={points}
          renderItem={(item: string) => <List.Item>{item}</List.Item>}
        />
      )}
    </ContainerStyled>
  );
};

export default TextField;
