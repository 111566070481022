



export const formSchema:any = {
    name: {
        type: "areaصاش",
        label: "Name",
        rules   : [{ required: true }],
        options:null,
        placeholder:"name"
    },
    birth: {
        type: "date",
        label: "الميلاد",
        rules   : undefined,
        options:null,
        placeholder:"تاريخ الميلاد"
    },
    email: {
        type: "email",
        label: "Email",
        rules   : undefined,
        options:null,
        placeholder:"email"

    },
    role: {
        type: "select",
        label: "Role",
        rules:undefined,
        placeholder:"select",
        options: [
            {
                label: "Admin",
                value: "admin"
            },
            {
                label: "User",
                value: "user"
            }
        ]
    }
}