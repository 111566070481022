import { IOption } from './../../Common/types';
import { IMUHAFAZATtype, IMANATIKtype, IFARAQtype, IPlaceType, IFaraq } from './types';

export const getMuhafazatLoadingSelector = (state:IMUHAFAZATtype) => {
    return state.loading
}

export const getMuhafazatErrorSelector = (state:IMUHAFAZATtype) => {
    return state.error
}


export const getMuhafazatSelector = (state:IMUHAFAZATtype) => {
    if(!state || !state.data ) return null

    console.log("state => ",state)
    const options:IOption[] = state.data.map((muhafaza:IPlaceType):IOption => ({value:muhafaza.id, label: muhafaza.name}) )

    return options
}

export const getMuhafazatIdSelector = (state:any) => {
    console.log("state from selector",state)
    if(!state || !state.general || !state.general.selectedId) return null


    return state.general.selectedId
}
export const getMantakaLoadingSelector = (state:IMANATIKtype) => {
    return state.loading
}

export const getMantakaErrorSelector = (state:IMANATIKtype) => {
    return state.error
}




export const getMantakaSelector = (state:IMANATIKtype) => {
    if(!state || !state.data ) return null
    const options:IOption[] = state.data.map((muhafaza:IPlaceType):IOption => ({value:muhafaza.id, label: muhafaza.name}) )

    return options
}



export const getFaraqLoadingSelector = (state:IFARAQtype) => {
    return state.loading
}

export const getFaraqErrorSelector = (state:IFARAQtype) => {
    return state.error
}


export const getFaraqSelector = (state:IFARAQtype) => {
    if(!state || !state.data ) return null
    const options:IOption[] = state.data.map((faraq:IFaraq):IOption => ({value:faraq.id, label: faraq.name}) )

    return options
}