import { IKashafa } from './types';


export const addKashafLoadingSelector = (state:IKashafa) => {
    return state.loading
}
export const addKashafErrorSelector = (state:IKashafa) => {
    return state.error
}


export const addKashafDataSelector = (state:IKashafa) => {
    if(!state || !state.data) return null
    return state.data
}

