import {
  addActivityDataSelector,
  addActivityErrorSelector,
  addActivityLoadingSelector,
} from './../../store/Activity/selectors'
import { connect } from 'react-redux'
import {
  getMuhafazatSelector,
  getMuhafazatLoadingSelector,
  getMuhafazatErrorSelector,
  getMantakaSelector,
  getMantakaLoadingSelector,
  getMantakaErrorSelector,
  getFaraqSelector,
  getFaraqLoadingSelector,
  getFaraqErrorSelector,
} from '../../store/Estmara/selectors'
import {
  getMuhafazatAction,
  getManatikAction,
  getMuhafazatSelectIdAction,
  getFaraqAction,
} from '../../store/Estmara/actions'
import { addActivityAction ,addActivityResetAction} from '../../store/Activity/actions'

const mapDispatchToProps = {
  addActivityResetAction,
  addActivityAction,
  getMuhafazatAction,
  getManatikAction,
  getMuhafazatSelectIdAction,
  getFaraqAction,
}

const mapStateToProps = (state: any) => {
  const activity = state.activity
  const Muhafazat = state.general.Muhafazat
  const Manatiq = state.general.Manatiq
  const Faraq = state.general.Faraq
  return {
    data: addActivityDataSelector(activity),
    error: addActivityErrorSelector(activity),
    loading: addActivityLoadingSelector(activity),
    mugafazat: getMuhafazatSelector(Muhafazat),
    muhafazatLoading: getMuhafazatLoadingSelector(Muhafazat),
    muhfazatError: getMuhafazatErrorSelector(Muhafazat),
    manatik: getMantakaSelector(Manatiq),
    manatikLoading: getMantakaLoadingSelector(Manatiq),
    mantakaError: getMantakaErrorSelector(Manatiq),
    faraq: getFaraqSelector(Faraq),
    faraqLoading: getFaraqLoadingSelector(Faraq),
    faraqError: getFaraqErrorSelector(Faraq),
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)
export default connected
