import produce, { Draft } from 'immer';
import { IGeneral } from '../general/types';


  export const getMuhafazatStartReducers = (state:IGeneral)=> {

    return  produce(state,(draft:Draft< IGeneral >) => {
          draft.Muhafazat.data=null;
          draft.Muhafazat.error = null;
          draft.Muhafazat.loading = true
      
      })
  
}

export const getMuhafazatSelectIdStartReducers = (state:IGeneral,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
        draft.Muhafazat.selectedId=action.payload
    })

}



export const getMuhafazatSuccessReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Muhafazat.error = null;
    draft.Muhafazat.loading = false;
    draft.Muhafazat.data = action.payload
    
    })

}

export const getMuhafazatFailReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Muhafazat.error = action.error
    draft.Muhafazat.loading = false;
    
    })

}

export const getMuhafazatResetReducers = (state:IGeneral )=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Muhafazat.error = null
    draft.Muhafazat.loading = false;
    draft.Muhafazat.data = null
    
    })

}





export const getManatikStartReducers = (state:IGeneral)=> {

    return  produce(state,(draft:Draft< IGeneral >) => {
      draft.Manatiq.data=null;
      draft.Manatiq.error = null;
      draft.Manatiq.loading = true
      
      })
  
}



export const getManatikSuccessReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Manatiq.error = null;
    draft.Manatiq.loading = false;
    draft.Manatiq.data = action.payload
    
    })

}

export const getManatikFailReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Manatiq.error = action.error
    draft.Manatiq.loading = false;
    
    })

}

export const getManatikResetReducers = (state:IGeneral )=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Manatiq.error = null
    draft.Manatiq.loading = false;
    draft.Manatiq.data = null
    
    })

}








 export  const getFaraqStartReducers = (state:IGeneral)=> {

    return  produce(state,(draft:Draft< IGeneral >) => {
          draft.Faraq.data=null;
          draft.Faraq.error = null;
          draft.Faraq.loading = true
      
      })
  
}



export const getFaraqSuccessReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Faraq.error = null;
    draft.Faraq.loading = false;
    draft.Faraq.data = action.payload
    
    })

}

export const getFaraqFailReducers = (state:IGeneral ,action:any)=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Faraq.error = action.error
    draft.Faraq.loading = false;
    
    })

}

export const getFaraqResetReducers = (state:IGeneral )=> {

  return  produce(state,(draft:Draft< IGeneral >) => {
    draft.Faraq.error = null
    draft.Faraq.loading = false;
    draft.Faraq.data = null
    
    })

}





