import { issetResponseResult } from './../global/respones';
import { AxiosError } from 'axios'
import { AxiosResponse } from 'axios'
import { put } from '@redux-saga/core/effects'
import { all, call, takeLatest } from 'redux-saga/effects'
import { NO_DATA, SERVER_ERROR } from '../../Common/errors'
import { LStorage } from '../../Config/singleton'
import StorageService from '../../Services/StorageService'
import { goTo } from '../../utils'
import {
  CHECK_AUTH,
  CHECK_IS_LOGEDIN,
  LOGIN,
  LOG_OUT,
} from '../global/actionTypes'
import { issetResponse, isValidResponse } from '../global/respones'
import {
  loginAction,
  loginFailAction,
  loginStartAction,
  loginSuccessAction,
  successAuthAction,
} from './actions'
import { authCheckApi, loginApi } from './api'
import { HomePath } from '../../Routes/constants'
import { ISAGADATA, IDataResponse, IStorageType, ILoginData } from './types'
import { redirect } from 'react-router-dom'
import { object } from 'yup'
import { Navigate } from 'react-router-dom'
import StringEncoding from '../../Services/StringEncoding'
import { CREDNTIAL_KEY, REMEMBER } from '../../Common/Constants'

// function* loginSaga(data:{type:string,params:any}) {
//     try {
//         yield put(loginStartAction())
//       const response = yield call(loginApi,data.params);

//       if(isValidResponse(response)){
//         yield put(loginSuccessAction(response.data.token))
//         yield localStorage.setItem('token',response.data.token)
//         goTo('/')

//       }else{

//         yield put(loginFailAction(NO_DATA));

//         }

//     } catch (e) {
//       yield put(loginFailAction('user or passowrd wrong'));
//     }
//   }

function* loginSaga({ type, params }: ISAGADATA) {
  try {
    yield put(loginStartAction())
    const body = { ...params, PWD: params.password }
    const response: AxiosResponse = yield call(loginApi, body)

    if (issetResponseResult(response)) {
      const { data }: { data: IDataResponse<string> } = response
      LStorage.SetByKey(
        CREDNTIAL_KEY,
        new StringEncoding<any>(body).encodingBase64().getDecoded(),
      )

      LStorage.SetToken(data.result)
      yield put(loginSuccessAction(data.result))
      yield put(successAuthAction(true))

      goTo('/home')
    } else {
      throw response
    }
  } catch (error) {
    const err: AxiosError | any = error
    console.log('error', error)
    yield _ErrorSwitcher(err)
  }
}

function* _ErrorSwitcher(err: any) {
  switch (err.response?.status) {
    case 401:
      yield put(loginFailAction(err.response?.data.Message))

      break
    case 400:
      yield put(loginFailAction(err.response?.data.Message))
      let errorMessage = ''
      if (err.response?.data?.errors) {
        for (const i of Object.keys(err.response?.data.errors)) {
          errorMessage += `${err.response?.data.errors[i]}, `
        }
        yield put(loginFailAction(errorMessage))
      }

      break
    default:
      yield put(loginFailAction(err.data?.Message))

      break
  }
}
// function* checkAuthSaga(data: { type: string }) {
//   try {
//     const token = LStorage.GetToken()

//     if (token && token !== 'true') {
//       goTo('/login')
//     }
//     yield put(successAuthAction(true))
//   } catch (err) {
//     //goTo('/login')
//   }
// }
function* logOutSaga(data: { type: string }) {
  try {
    yield put(successAuthAction(false))

    LStorage.RemoveToken()
    goTo('/login')
  } catch (err) {
    //goTo('/login')
  }
}

/// check is remmebr me
function* CheckIsLoggedInSaga(data: { type: string }) {
  try {
    const IsRememberMe: IStorageType = LStorage.GetByKey(REMEMBER)
    if (IsRememberMe) {
      const cred: IStorageType = yield LStorage.GetByKey(CREDNTIAL_KEY)

      if (cred) {
        const DecodedCred: ILoginData = StringEncoding.decodeBase64<ILoginData>(
          cred,
        )

        yield put(loginAction(DecodedCred))
      }
    }
  } catch {}
}

// TODO inside routring
function* checkAuthSaga(data: { type: string }) {
  try {
    const token = LStorage.GetToken()
    if (token) {
      const response: AxiosResponse = yield call(authCheckApi)
      if (!issetResponse(response)) {
        LStorage.RemoveToken()
        goTo('/login')
      }
    } else {
      goTo('/login')
    }
  } catch (err) {
    goTo('/login')
  }
}

function* generalSaga() {
  yield all([takeLatest(LOGIN, loginSaga)])
  yield all([takeLatest(CHECK_AUTH, checkAuthSaga)])
  yield all([takeLatest(LOG_OUT, logOutSaga)])
  yield all([takeLatest(CHECK_IS_LOGEDIN, CheckIsLoggedInSaga)])
}

export default generalSaga
