import  styled  from 'styled-components';
import { IPropTheme } from '../../../Common/types';



export const ContainerStyled = styled.div`
    display:flex;
    flex-direction:column;
    direction:rtl;
    label{
        font-size:13px;
        color:#696464;
    } 

    .ant-picker {
        width:100%;
    }
`

export const ErrorStyled = styled.div`

    color:${(props:IPropTheme) => props.theme.colors.cardColor};ß

`