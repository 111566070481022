import { getHistoryDataSelector, getHistoryLoadingSelector, getHistoryErrorSelector } from './../../store/History/selectors';
import { connect } from 'react-redux'
import { getHistoryAction } from '../../store/History/actions';

const mapDispatchToProps = {
  getHistoryAction,
}

const mapStateToProps = (state: any) => {
  const history = state.history;
    return {
      data: getHistoryDataSelector(history),
      loading: getHistoryLoadingSelector(history),
      error: getHistoryErrorSelector(history),
  
    }
}

const connected = connect(mapStateToProps, mapDispatchToProps)
export default connected
