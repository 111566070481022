import React, { FC } from "react";
import { Link } from "react-router-dom";
import MobileLayout from "../../../Components/Mobile/MobileLayout";
import { HomePath } from "../../../Routes/constants";
import { StyledContainer } from "./styled";

// TODO get thegeolocation of the user

const NetworkError: FC<{}> = ({}) => {
  return (
    <StyledContainer>
      <div>
        حدثت مشكله في الاتصال بالانترنت جيك الشبكخ او اتصل بالفريق التقني
      <br />
      <Link to={HomePath}>اعاده التحميل </Link>
      </div>
    </StyledContainer>
  );
};

export default NetworkError;
