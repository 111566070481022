import { IStorageService } from './IStorageService';


const TOKEN:string = "token";
export default  class StorageService  implements IStorageService {

    SetToken(token: string): void {
        localStorage.setItem(TOKEN,token);
    }

    GetByKey(title: string): string | null {
        const token =  localStorage.getItem(title)

        return token
    }
    RemoveByKey(title: string): void {
        localStorage.removeItem(title)
    }
    SetByKey(title: string, value: any): void {
        localStorage.setItem(title,value)
    }
 
     
    public static GetService(){
        return new StorageService();
    }

    public   GetToken(): string | null{
        const token =  localStorage.getItem(TOKEN)
        
        return token
    }

    public  RemoveToken(): void{
         localStorage.removeItem(TOKEN)
    }


 
}

