import { logoutAction } from './../../../../store/general/actions';
import { connect } from "react-redux"

const mapDispatchToProps = {
        logoutAction
}


const mapStateToProps = (state:any) => {
    return {
    }
}
  
const connected = connect(mapStateToProps, mapDispatchToProps)
export default  connected