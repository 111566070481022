import { IHISTORYtype } from "./types"

export const getHistoryLoadingSelector = (state:IHISTORYtype) => {
    if(!state ) return false
    return state.loading
}
export const getHistoryErrorSelector = (state:IHISTORYtype) => {
    return state.error
}


export const getHistoryDataSelector = (state:IHISTORYtype) => {
    if(!state || !state.data) return null
    return state.data
}

