
export const HIDDEN = ""


export const COLORS:any={
    Activity:"green",
    KaderInfo:"red",
    Jalsa:"blue",
    Visit:"pink"
}


export const MESGS:any = {
    Activity:"لقد قمت باضافه نشاط ",
    KaderInfo:"لقد قمت باضافه عنصر جديد",
    Jalsa:"لقد قمت باضافه جلسه جديدة",
    Visit:"لقد قمت  بزياره"
}
// Activity; KaderInfo; Jalsa; Visit