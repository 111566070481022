import { IVisitsSchema } from '../Containers/Visits/types'

export const _onEnterPointsNotes = (
  val: any,
  key: string,
  POIINTS_KEYS: string[],
  setSchema: any,
  setNotePoints: any,
) => {
  if (POIINTS_KEYS.includes(key)) {
    setSchema((vit: any) => ({
      ...vit,
      [key]: { ...vit[key], points: val.split('\n') },
    }))
    setNotePoints((p: any) => ({ ...p, [key]: val.split('\n').join(',') }))
  }
}

export const _resetPointsFromSchema = (
  POIINTS_KEYS: string[],
  setSchema: any,
) => {
  POIINTS_KEYS.forEach((key: string) => {
    setSchema((vit: any) => ({
      ...vit,
      [key]: { ...vit[key], points: null },
    }))
  })
}

export const _updateMuhafazatInitOption = (
  key: string,
  values: any[],
  schema: any,
  setSchema: any,
) => {
  const select = { ...schema[key], options: values }
  setSchema((vit: any) => ({ ...vit, [key]: select }))
}

export const _submitAfterValidate = (
  values: any,
  POIINTS_KEYS: string[],
  addAction: any,
  notePoints: any,
) => {
  const date: string = values.Date.format()
  let valueData = { ...values, Date: date }
  POIINTS_KEYS.forEach((key: string) => {
    valueData = { ...valueData, [key]: notePoints[key] }
  })
  addAction(valueData)
}





export const _showOtherOrHide = (key: string, hid: boolean,changeScema:any) => {
  const textProps = { hidden: hid };
  changeScema((schem: any) => ({
    ...schem,
    [key]: { ...schem[key], textProps },
  }));
};
