import { addVisistStartAction, addVisistSuccessAction, addVisistFailAction } from './actions';
import { NO_DATA } from './../../Common/errors';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  ADD_VISIT } from './../global/actionTypes';
import {  addVisittApi } from './api';
import { ISagaData } from '../global/types';
import { issetResponse, issetResponseResult } from '../global/respones';
import { SERVER_ERROR } from '../../Common/errors';
import { AxiosResponse, AxiosError } from 'axios';



function* addVisitSaga(data:ISagaData) {
    try {
        yield put(addVisistStartAction())
        


      const response:AxiosResponse  = yield call(addVisittApi,data.params);

      if(issetResponseResult(response)){
        yield put(addVisistSuccessAction(response.data.result))
        
      }else{
        console.log("response",response)
       throw response;

        }

    } catch (e:any) {
     
      yield put(addVisistFailAction(e.code));
    }
  }




 function* VisitsSaga() {
    yield all([takeLatest(ADD_VISIT, addVisitSaga)]);


  }

  export default VisitsSaga