import React from "react";
import { FC } from "react";
import { FlexColumn, FlexRow } from "../../../theme/styled";
import { Icon, StyledContainer, SubTitle, Title } from "./styled";
import { Props } from "./types";

const Tab: FC<Props> = ({ action, title, icon, subTitle }) => {
  return (
    <StyledContainer onClick={action}>
      <FlexColumn>
        <Title>{title}</Title>
        <SubTitle>{subTitle && subTitle}</SubTitle>
      </FlexColumn>
      <Icon>{icon}</Icon>
    </StyledContainer>
  );
};

export default Tab;
