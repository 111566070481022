import { ADD_ACTIVITY, ADD_ACTIVITY_START, ADD_ACTIVITY_SUCCESS, ADD_ACTIVITY_FAIL, ADD_ACTIVITY_RESET } from './../global/actionTypes';


export const addActivityAction = (params:any) => {
    return {
        type:ADD_ACTIVITY,
        params
    }
} 

export const addActivityStartAction = () => {
    return {
        type: ADD_ACTIVITY_START,
    }
}

export const addActivitySuccessAction = (payload:any) => {
    return {
        type:ADD_ACTIVITY_SUCCESS ,
        payload
    }
}


export const addActivityFailAction = (error:any) => {
    return {
        type:ADD_ACTIVITY_FAIL,
        error
    }
}



export const addActivityResetAction = () => {
    return {
        type: ADD_ACTIVITY_RESET
    }
}


