import { getMuhafazatAction, getManatikAction, getMuhafazatSelectIdAction, getFaraqAction } from './../../store/Estmara/actions';
import { getMuhafazatLoadingSelector, getMuhafazatErrorSelector, getMantakaSelector, getMantakaErrorSelector, getMantakaLoadingSelector, getFaraqSelector, getFaraqLoadingSelector, getFaraqErrorSelector } from './../../store/Estmara/selectors';
import { connect } from 'react-redux'
import { getMuhafazatSelector } from '../../store/Estmara/selectors'
import { addVisistAction, addVisistResetAction } from '../../store/Visits/actions'
import { addVisitDataSelector, addVisitErrorSelector, addVisitLoadingSelector } from '../../store/Visits/selectors'


const mapDispatchToProps = {
    addVisistAction,
    addVisistResetAction,
    getMuhafazatAction,
    getManatikAction,
    getMuhafazatSelectIdAction,
    getFaraqAction

}

const mapStateToProps = (state: any) => {
  const visits = state.visits
  const Muhafazat= state.general.Muhafazat
  const Manatiq = state.general.Manatiq
  const Faraq = state.general.Faraq

  return {
    data:addVisitDataSelector(visits),
    loading:addVisitLoadingSelector(visits),
    error:addVisitErrorSelector(visits),  
    mugafazat:getMuhafazatSelector(Muhafazat),
    muhafazatLoading:getMuhafazatLoadingSelector(Muhafazat),
    muhfazatError:getMuhafazatErrorSelector(Muhafazat),
    manatik:getMantakaSelector(Manatiq),
    manatikLoading:getMantakaLoadingSelector(Manatiq),
    mantakaError:getMantakaErrorSelector(Manatiq),
    faraq:getFaraqSelector(Faraq),
    faraqLoading:getFaraqLoadingSelector(Faraq),
    faraqError:getFaraqErrorSelector(Faraq)

  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)
export default connected



