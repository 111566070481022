import axios from 'axios'
import { NetwrokErrorPath } from '../Routes/constants';
import { goTo } from '../utils';
import { API_KEY, API_KEY_VAL, BASE_URI } from './Constants'
import { LStorage } from './singleton';


export const axiosInstance = axios.create({
    baseURL:BASE_URI
})


// this interceptor to intercept the request and attach token to it when send request

// // LocalStorageService
// const localStorageService = LocalStorageService.getService()

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config:any) => {
   const token = LStorage.GetToken();

   if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers[API_KEY] = API_KEY_VAL
    config.headers['Content-Type'] = 'application/json';
    return config
  },
    (  error: any) => {
        // TODO handle error to throw the user to error page
    Promise.reject(error)
  }
)



// response interceptor

axiosInstance.interceptors.response.use(
    (    response: any) => {


      return response
    },
    function (error: { config: any; code?:string; response: { status: number; };  }) {
      const originalRequest = error.config
  
      console.log("errors =>",error)
      console.log("errors =>",error,error.code)
      // TODO impelemnt more response status, to handle errors
      if (
        error.response.status === 401 || error.response.status === 403
      ) {
        goTo('/login')
        return Promise.reject(error)
      }

      if(error.code && error.code === "ERR_NETWORK"){
        console.log("err network")
        goTo(NetwrokErrorPath)
        return Promise.reject(error)
      }
  
      if (error.response.status === 401 && !originalRequest._retry) {
       // originalRequest._retry = true
        // const refreshToken = localStorageService.getRefreshToken()
        // return axios
        //   .post('/auth/token', {
        //     refresh_token: refreshToken
        //   })
        //   .then((res: { status: number; data: any; }) => {
        //     if (res.status === 201) {
        //       localStorageService.setToken(res.data)
        //       axios.defaults.headers.common['Authorization'] =
        //         'Bearer ' + localStorageService.getAccessToken()
        //       return axios(originalRequest)
        //     }
        //   })
      }
      return Promise.reject(error)
    }
  )