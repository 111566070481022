import { ADD_VISIT_START, ADD_VISIT_SUCCESS, ADD_VISIT_FAIL, ADD_VISIT_RESET } from './../global/actionTypes';
import produce, { Draft } from 'immer';
import {  IVisits } from './types';

const initialState: IVisits = {
    data:null,
    loading:false,
    error:null,

  };



  const addVisitStartReducers = (state:IVisits)=> {

    return  produce(state,(draft:Draft<IVisits>) => {
          draft.data=null;
          draft.error = null;
          draft.loading = true
      
      })
  
}



const addVisitSuccessReducers = (state:IVisits,action:any)=> {

  return  produce(state,(draft:Draft<IVisits>) => {
        draft.error = null;
        draft.loading = false;
        draft.data = action.payload
    
    })

}

const addVisitFailReducers = (state:IVisits,action:any)=> {

  return  produce(state,(draft:Draft<IVisits>) => {
        draft.error = action.error
        draft.loading = false;
    
    })

}

const addVisitResetReducers = (state:IVisits)=> {

  return  produce(state,(draft:Draft<IVisits>) => {
        draft.error = null
        draft.loading = false;
        draft.data = null
    
    })

}


 function VisitReducer(state = initialState, action: any) {
    switch (action.type) {
     case ADD_VISIT_START : return addVisitStartReducers(state);
     case ADD_VISIT_SUCCESS: return addVisitSuccessReducers(state,action);
     case ADD_VISIT_FAIL: return addVisitFailReducers(state,action)
     case ADD_VISIT_RESET: return addVisitResetReducers(state)

      default: return state
    }
  };
  

  export default VisitReducer

