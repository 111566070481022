import { GET_MUHAFAZAT_ENDPOINT,GET_MANATIK_ENDPOINT,GET_FARAQ_ENDPOINT } from './../../Config/ApiEndpoints';
import { axiosInstance } from "../../Config/api"
import { MuhafazaType } from './types';


export const getMuhafazatApi:any = async () => {

    try {
        var resp = await axiosInstance.get(`${GET_MUHAFAZAT_ENDPOINT}?type=${MuhafazaType.MUHAFAZA}`)
      
        return resp;
    } catch (error) {
        return error;
    }

}




export const getManatikApi:any = async (params:string) => {

    try {
        var resp = await axiosInstance.get(`${GET_MANATIK_ENDPOINT}?type=${MuhafazaType.MANTAKA}&parentId=${params}`)
      
        return resp;
    } catch (error) {
        return error;
    }

}




export const getFaraqApi:any = async (params:string) => {

    try {
        var resp = await axiosInstance.get(`${GET_FARAQ_ENDPOINT}?MuhafazaId=${params}`)
      
        return resp;
    } catch (error) {
        return error;
    }

}

