import { FormInstance, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { DATE_FORMAT } from "../../Common/Constants";
import CustomTextArea from "../../Components/Form/CustomTextArea/Index";
import Forma from "../../Components/Form/Forma";
import SelectFormField from "../../Components/Form/SelectFormElement/Index";
import SubmitButton from "../../Components/Form/SubmitButton/Index";
import MobileLayout from "../../Components/Mobile/MobileLayout";
import {
  _onEnterPointsNotes,
  _resetPointsFromSchema,
  _submitAfterValidate,
  _updateMuhafazatInitOption,
} from "../../Helpers/formHelpers";
import { validateMessages } from "../Kashafa/Constants";

import connected from "./connects";
import { POIINTS_KEYS, VisitsSchema } from "./constants";
import { IVisitsSchema, Props } from "./types";

// TODO get thegeolocation of the user

// TODO add new
const VisitsContainer: FC<Props> = ({
  loading,
  error,
  data,
  addVisistAction,
  addVisistResetAction,
  mugafazat,
  muhafazatLoading,
  muhfazatError,
  manatik,
  manatikLoading,
  mantakaError,
  getMuhafazatAction,
  getMuhafazatSelectIdAction,
  getManatikAction,
  faraq,
  faraqError,
  faraqLoading,
  getFaraqAction,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [notePoints, setNotePoints] = useState<any>({});
  const [schema, setSchema] = useState<IVisitsSchema | any>(VisitsSchema);

  const formRef = React.createRef<FormInstance>();
  //   { setSubmitting, resetForm, setStatus }
  const onSubmit = (values: any) => {
    _submitAfterValidate(
      values,
      Object.keys(POIINTS_KEYS),
      addVisistAction,
      notePoints
    );

  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("errorInfo => ", errorInfo);
  };
  const onSelectChange = (value: any, option: any[], key: string) => {
    _keyChangeHandlerForSelect(key, value);
  };

  const _keyChangeHandlerForSelect = (key: string, value: any) => {
    switch (key) {
      case "City":
        getMuhafazatSelectIdAction(value);
        getManatikAction(value);
        break;
      case "State":
        getFaraqAction(value);
        break;
      default:
        break;
    }
  };



  const _resetAfterSubmit = () => {
    addVisistResetAction();
    setNotePoints({});
    formRef.current!.resetFields();
    _resetPointsFromSchema(Object.keys(POIINTS_KEYS), setSchema);
  };
  useEffect(() => {
    // success adding the kashaf onlineπ
    if (data) {
      _resetAfterSubmit();
    }
  }, [data]);

  const _onEnterNotes = (val: any, key: string) => {
    _onEnterPointsNotes(
      val,
      key,
      Object.keys(POIINTS_KEYS),
      setSchema,
      setNotePoints
    );
  
  };

  useEffect(() => {
    // fetching the muhafazat at first
    getMuhafazatAction();
  }, [getMuhafazatAction]);

  useEffect(() => {
    if (mugafazat) {
      _updateMuhafazatInitOption("City", mugafazat, schema, setSchema);
    }
  }, [mugafazat]);

  useEffect(() => {
    if (manatik) {
      _updateMuhafazatInitOption("State", manatik, schema, setSchema);
    }
  }, [manatik]);

  useEffect(() => {
    if (faraq) {
      _updateMuhafazatInitOption("Team", faraq, schema, setSchema);
    }
  }, [faraq]);

  const keysForm = Object.keys(schema);
  return (
    <div>
      <MobileLayout
        title="الزيارات"
        back
        error={faraqError || muhfazatError || error || mantakaError}
        success={Boolean(data)}
        onSuccess={_resetAfterSubmit}
      >
        {loading && "loading sending data to backend"}
        <Forma
          initialValues={formData}
          onSubmit={onSubmit}
          validateMessages={validateMessages}
          onSubmitFailed={onSubmitFailed}
          name="vertical"
          formRef={formRef}
          autoComplete
        >
          {keysForm.map((key, ind) => (
            <SelectFormField
              key={key}
              label={schema[key].label}
              type={schema[key].type}
              placeholder={schema[key].placeholder}
              name={key}
              options={schema[key].options}
              rules={schema[key].rules}
              onSelectChange={onSelectChange}
              loading={muhafazatLoading || manatikLoading || faraqLoading}
              onEnter={_onEnterNotes}
              points={schema[key].points}
            />
          ))}

          <SubmitButton title="حفظ" isSubmit={loading} />
          {loading&&  <Spin size="large" /> }
        </Forma>
      </MobileLayout>
    </div>
  );
};

export default connected(VisitsContainer);
