import { GET_HISTORY, GET_HISTORY_SUCCESS,GET_HISTORY_FAIL,GET_HISTORY_START,GET_HISTORY_RESET     } from "../global/actionTypes"
import { IDataResponse } from "../general/types"


export const getHistoryAction   = (params:any) => {
    return {
        type:GET_HISTORY,
        params
    }
} 

export const getHistoryStartAction = () => {
    return {
        type: GET_HISTORY_START,
    }
}

export const  getHistorySuccessAction = (payload:IDataResponse<any>) => {
    return {
        type:GET_HISTORY_SUCCESS ,
        payload
    }
}


export const  getHistoryFailAction = (error:any) => {
    return {
        type:GET_HISTORY_FAIL,
        error
    }
}



export const  getHistoryResetAction = () => {
    return {
        type: GET_HISTORY_RESET
    }
}


