import styled from 'styled-components'


type IPropTheme =  {
    theme:any;
    wait:any
}
export const ButtonStyled = styled.div`
   //  border-radius:10px;
    color:#fff;
    background:${(props:IPropTheme) => props.wait?props.theme.colors.disableButton :props.theme.colors.primary};
    text-align:center;
    padding:10px;
    cursor:pointer;
    box-shadow:1px 1px 1px #eee;
    :&focus,:&active{
        box-shadow:0px 0px 0px #eee;

    }
`