export default class StringEncoding<Tdata> {
    str ?:Tdata;
    public decoded:string;

    constructor(data:Tdata , decoded= ""){
        this.str = data
        this.decoded = decoded
    }
    
    public  GetService(){
        return new StringEncoding(this.str)
    }

    public  getDecoded():string{
        return this.decoded
    }
    public encodingBase64(){
        this.decoded = btoa(JSON.stringify(this.str));
        return this;
    }

    public decodingBase64(){
        this.str = JSON.parse( atob(this.decoded));
        return this;
    }

    public static decodeBase64<T>(hash:string): T{
        return JSON.parse( atob(hash));
    }

    public static encodeBase64<Td>(key:Td): string{
        return btoa(JSON.stringify(key));
    }
}