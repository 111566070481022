import { ADD_KASHAF_ENPOIND } from './../../Config/Constants';
import { axiosInstance } from "../../Config/api"


export const addKashafApi:any = async (params:any) => {

    try {
        var resp = await axiosInstance.post(ADD_KASHAF_ENPOIND,params)
      
        return resp;
    } catch (error) {
        return error;
    }

}

