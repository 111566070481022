import React, { FC, useEffect, useState } from "react";
import Forma from "../../Components/Form/Forma";
import SubmitButton from "../../Components/Form/SubmitButton/Index";
import MobileLayout from "../../Components/Mobile/MobileLayout";
import Tab from "../../Components/Mobile/Tab/Index";
import IconUsers from "../../Components/SvgIcons/icon-users";

import { IsMobile } from "../../Hooks/Mobile";
import connected from "./connects";
import { formSchema } from "./constants";
import { initForm } from "../../Hooks/Forms";
import SelectFormField from "../../Components/Form/SelectFormElement/Index";
import TextField from "../../Components/Form/TextField/Index";
import DatePickerField from "../../Components/Form/DateField/Index";
import { BasiInfoKashafSchema, validateMessages } from "../Kashafa/Constants";
import { ITab, Props } from "./types";
import { useNavigate } from "react-router-dom";
import {
  AddActivity,
  AddJalsa,
  AddKashaf,
  AddVisits,
  HistoryPath,
} from "../../Routes/constants";
import IconTrain from "../../Components/SvgIcons/icon-train";
import { goTo } from "../../utils";
import MenuHeader from "../../Components/Mobile/Menu/Index";
import { HistoryOutlined } from "@ant-design/icons";
import { IconStyled } from "./styled";
const Home: FC<Props> = ({ checkAuthAction }) => {
  // const isMobile = IsMobile();
  const [formData, setFormData] = useState<any>({});
  const [validationSchema, setValidationSchema] = useState<any>({});

  const navigate = useNavigate();

  useEffect(() => {
    const [forma, validation] = initForm(formSchema);
    setFormData(forma);
    setValidationSchema(validation);
  }, []);

  //   { setSubmitting, resetForm, setStatus }
  const onSubmit = (values: any) => {
    console.log("values => ", values);
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("errorInfo => ", errorInfo);
  };

  const _naviageTo = (path: string) => {
    navigate(path);
    // goTo(path);
  };
  const tabs: ITab[] = [
    {
      title: "اضافه كشاف جديد",
      subTitle: "من هنا يتم اضافه الكشافه الجدد",
      icon: <IconUsers />,
      action: () => _naviageTo(AddKashaf),
      id: 1,
    },
    {
      title: "اضافه جلسه ",
      subTitle: "من هنا يتم اضافه الجلسات الجديدة",
      icon: <IconUsers />,
      action: () => _naviageTo(AddJalsa),
      id: 2,
    },
    {
      title: "اضافه زيارة ",
      subTitle: "من هنا يتم اضافه الزيارات",
      icon: <IconUsers />,
      action: () => _naviageTo(AddVisits),
      id: 3,
    },

    {
      title: "اضافه نشاط ",
      subTitle: "من هنا يتم اضافه النشاطات",
      icon: <IconUsers />,
      action: () => _naviageTo(AddActivity),
      id: 4,
    },

    {
      title: "الحركات ",
      subTitle: "سجل الحركات التي قمت بها",
      icon:(<IconStyled>  <HistoryOutlined /></IconStyled>),
      action: () => _naviageTo(HistoryPath),
      id: 5,
    },
  ];

  useEffect(() => {
    checkAuthAction();
  }, []);
  const keysForm: string[] = Object.keys(BasiInfoKashafSchema);
  return (
    <div>
      {true && (
        <MobileLayout title="الصفحه الرئيسيه" letfIcon={<MenuHeader />}>
          {tabs.map((_tab: ITab) => (
            <Tab
              title={_tab.title}
              subTitle={_tab.subTitle}
              icon={_tab.icon}
              action={_tab.action}
              key={_tab.id}
            />
          ))}
        </MobileLayout>
      )}
    </div>
  );
};

export default connected(Home);
