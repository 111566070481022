import styled from 'styled-components'
import { IPropTheme } from '../../../Common/types'

export const StyledContainer = styled.div`
    background:${(props: IPropTheme) => props.theme.colors.gray};
    border-radius: 10px;
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 0px 10px #eee;
    color: ${(props: IPropTheme) => props.theme.colors.bluePrimary};
    border-bottom: 2px solid ${(props: IPropTheme) =>
      props.theme.colors.carrot};;
    margin: 13px;
    /* border: 0.5px solid #00000045; */
    padding: 22px;

    svg{
        fill:${(props: IPropTheme) => props.theme.colors.bluePrimary};
        //stroke:${(props: IPropTheme) => props.theme.colors.bluePrimary};
    }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: ${(props: IPropTheme) => props.theme.fontSizes.title};
`

export const SubTitle = styled.div`
  font-size: ${(props: IPropTheme) => props.theme.fontSizes.subTitle};
  color: ${(props: IPropTheme) => props.theme.colors.grey};
`

export const Icon = styled.div``
