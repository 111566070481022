import React, { FC } from "react";
import { BrowserRouter as Router , Routes ,createBrowserRouter,
  RouterProvider,
  Route } from "react-router-dom";
import NotFound from "../Components/NotFound";
import Login from "../Containers/Auth";
import PrivateRoute from "../HOC/PrivateRoute";
import {
  AddActivity,
  AddJalsa,
  AddKashaf,
  AddVisits,
  AllPages,
  HistoryPath,
  HomePath,
  LoginPath,
  NetwrokErrorPath,
} from "./constants";

import Home from "../Containers/Home/Index";
import AddKashafa from "../Containers/Kashafa/Index";
import JalasatComponent from "../Containers/Jalasat/Index";
import AddVisistsComponent from "../Containers/Visits/Index";
import AddActivityComponent from "../Containers/Activity/Index";
import connected from "./connects";
import { Props } from "./types";
import NetworkError from "../Containers/Errors/NetworkError";
import HistoryContainer from "../Containers/History/Index"

export  const router:any = createBrowserRouter([
  {
    path: LoginPath,
    element: <Login />,
  },
  {
    path: HomePath,
    element: <Home />,
  },
  {
    path: AddKashaf,
    element: <AddKashafa />,
  },
  {
    path: AddActivity,
    element: <AddActivityComponent />,
  },

  {
    path: AddJalsa,
    element: <JalasatComponent />,
  },

  {
    path: AddVisits,
    element: <AddVisistsComponent />,
  },
  {
    path: NetwrokErrorPath,
    element: <NetworkError />,
  },
  {
    path: HistoryPath,
    element: <HistoryContainer />,
  },
]);

const AppRoutes: FC<Props> = ({isAuth}) => {
  
  return (
    <>
        <RouterProvider  router={router}   />
   </> 
  );
}
export default connected(AppRoutes);