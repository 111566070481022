import React from "react";
import { FC } from "react";
import { ContainerStyled } from "./styled";
import { Props } from "./types";
import { Button, Form } from "antd";

const SubmitButton: FC<Props> = ({ title, tailLayout, onReset,isSubmit }) => {
  return (
    <ContainerStyled className="field">
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" disabled={isSubmit}>
          {title}
        </Button>
        {onReset && (
          <Button htmlType="button" onClick={onReset}>
            مسح الفورم
          </Button>
        )}
      </Form.Item>
    </ContainerStyled>
  );
};

export default SubmitButton;
