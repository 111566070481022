import { NO_DATA } from './../../Common/errors';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  GET_HISTORY , GET_HISTORY_SUCCESS, GET_HISTORY_RESET, GET_HISTORY_START, GET_HISTORY_FAIL } from './../global/actionTypes';
import {   getHistorySuccessAction, getHistoryStartAction, getHistoryFailAction } from './actions';

import {  getHistoryApi } from './api';
import { ISagaData } from '../global/types';
import { issetResponse, issetResponseResult } from '../global/respones';
import { SERVER_ERROR } from '../../Common/errors';
import { AxiosResponse } from 'axios';



function* getHistorySaga(data:ISagaData) {
    try {
        yield put(getHistoryStartAction())
        


      const response:AxiosResponse = yield call(getHistoryApi,data.params);

      if(issetResponseResult(response)){
        yield put(getHistorySuccessAction(response.data.result))
        
      }else{

        yield put(getHistoryFailAction(NO_DATA));

        }

    } catch (e) {
      yield put(getHistoryFailAction(SERVER_ERROR));
    }
  }




 function* kashafaSaga() {
    yield all([takeLatest(GET_HISTORY, getHistorySaga)]);


  }

  export default kashafaSaga