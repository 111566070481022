import { GET_HISTORY_ENDPOINT } from './../../Config/Constants';
import { axiosInstance } from "../../Config/api"
import { getQueryString } from '../../utils';


export const getHistoryApi:any = async (params:any) => {

    try {
        var resp = await axiosInstance.get(`${GET_HISTORY_ENDPOINT}?${getQueryString(params)}`)
      
        return resp;
    } catch (error) {
        return error;
    }

}

