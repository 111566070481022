import { ClockCircleOutlined } from "@ant-design/icons";
import { FormInstance, Spin, Timeline } from "antd";
import { connect } from "http2";
import React, { FC, useEffect, useState } from "react";
import { NO_DATA } from "../../Common/errors";
import MobileLayout from "../../Components/Mobile/MobileLayout";
import connected from "./connects";
import TimelineList from "./TimelineList";
import { Props } from "./Types";

const HistoryContainer: FC<Props> = ({
  data,
  loading,
  error,
  getHistoryAction,
}) => {
  useEffect(() => {
    getHistoryAction({});
  }, []);
  return (
    <div style={{textAlign:"center"}}>
      <MobileLayout title="تاريخ الحركات" back>
        {loading && !error && <Spin size="large" style={{marginTop:"20px"}}  />}
        {error && <div>{NO_DATA}</div>}
        {Boolean(data && data.length > 0) && <TimelineList data={data} />}
      </MobileLayout>
    </div>
  );
};

export default connected(HistoryContainer);
