import { IJalasatInfo } from './Types'

export const JalasatSchema: IJalasatInfo | any = {
  City: {
    type: 'select',
    label: 'المحافظه',
    options: [],
    placeholder: 'المحافظه',
    rules: [
      {
        required: true,
      },
    ],
  },
  State: {
    type: 'select',
    label: 'المنطقة',
    options: [],
    placeholder: 'المنطقة',
    rules: [
      {
        required: true,
      },
    ],
  },
  Team: {
    type: 'select',
    label: 'الفوج او الفرقه',
    options: [],
    placeholder: 'الفوج او الفرقه',
    rules: [
      {
        required: true,
      },
    ],
  },
  TargetCategory: {
    type: 'select',
    label: ' الفئه',
    options: [
      {
        value: 0,
        label: 'براعم',
      },
      {
        value: 1,
        label: 'براعم  واشبال',
      },
      {
        value: 2,
        label: 'اشبال',
      },
      {
        value: 3,
        label: 'كشافه',
      },
      {
        value: 4,
        label: 'كشافه و جواله',
      },
      {
        value: 5,
        label: ' جواله',
      },
    ],
    placeholder: 'الفئة',
    rules: [
      {
        required: true,
      },
    ],
  },

  Category: {
    type: 'select',
    label: 'جنس الفئه',
    options: [
      {
        value: 0,
        label: 'اخوة',
      },
      {
        value: 1,
        label: 'اخوات',
      },
    ],
    placeholder: 'الفئة',
    rules: [
      {
        required: true,
      },
    ],
  },
  JalasatTypes: {
    type: 'select',
    label: 'نوع الجلسة',
    options: [
      {
        value: 0,
        label: 'جلسة مفوضيه المحافظات',
      },
      {
        value: 1,
        label: 'جلسة الفرق او الفوج',
      },
      {
        value: 2,
        label: 'جلسة مسؤولي اللجان',
      },
      {
        value: 3,
        label: 'جلسة المفوضيه العامة',
      },

      {
        value: 4,
        label: 'جلسة مفوضيات الاختصاص',
      },
      {
        value: 5,
        label: 'اخرى',
      },
    ],
    placeholder: 'نوع الجلسة',
    rules: [
      {
        required: true,
      },
    ],
  },
  jalasatTypeName: {
    type: 'text',
    label: 'الاسم الجلسه',
    options: undefined,
    placeholder: 'الاسم الجلسه',
    rules: [
      {
        required: true,
      },
    ],
    textProps: {
      hidden: true,
    },
  },
  Date: {
    type: 'date',
    label: 'التاريخ',
    options: undefined,
    placeholder: 'التاريخ',
    rules: [
      {
        required: true,
      },
    ],
  },
  // DONE add number type to the formm
  ParticipantNum: {
    type: 'number',
    label: 'عدد المشاركين',
    options: undefined,
    placeholder: 'عدد المشاركين',
    rules: [
      {
        required: true,
      },
    ],
  },
  Recommendations: {
    type: 'area',
    label: 'نقاط المقررات',
    options: undefined,
    placeholder: 'نقاط المقررات',
  },
  Notes: {
    type: 'area',
    label: 'ملاحظات عامه',
    options: undefined,
    placeholder: 'ملاحظات عامه',
  },
}

export const POIINTS_KEYS: any = {
  Recommendations: 'Recommendations',
}


export const HIDDEN_FIELD_KEY = "jalasatTypeName"