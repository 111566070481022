import PinIcon from "../../Components/SvgIcons/icon-pin";
import { IActivitySchema } from "./types";

export const ActivitySchema:IActivitySchema|any = {
    City: {
        type: 'select',
        label: 'المحافظه',
        options: [],
        placeholder: 'المحافظه',
        rules: [
          {
            required: true,
          },
        ],
      },
      State: {
        type: 'select',
        label: 'المنطقة',
        options: [ ],
        placeholder: 'المنطقة',
        rules: [
          {
            required: true,
            onConditon:'City'
          },
        ],
      },
      Location:{
        type:'text',
        label:"الموقع",
        placeholder:"الموقع",
        rules:[{
            required:true
        }]
    },

      ActivityName:{
        type:'text',
        label:"اسم النشاط",
        placeholder:"اسم النشاط",
        rules:[{
            required:true
        }]
    },

    ActivityType:{
        type:'text',
        label:"نوع النشاط",
        placeholder:"نوع النشاط",
        rules:[{
            required:true
        }]
    },

    ActivityField:{
        type:'select',
        textProps:{
          mode:"multiple",
        },
        options:[{
          value:0,
          label:"ديني ثقافي"
        },
        {
          value:1,
          label:"كشفي"
        },
        {
          value:2,
          label:"ترفيهي"
        },
        
        {
          value:3,
          label:"اجتماعي"
        },
        {
          value:4,
          label:"صحي"
        },
        {
          value:5,
          label:"بيئي"
        },
        {
          value:6,
          label:"حركي رياضي"
        },
        
        {
          value:7,
          label:"علمي"
        },
        
        {
          value:8,
          label:"فني"
        },
        
        
      
      ],
        label:"مجال النشاط",
        placeholder:"مجال النشاط",
        rules:[{
            required:true
        }]
    },


    TargetCategory: {
      type: 'select',
      label: ' الفئه',
      options: [
        {
          value: 0,
          label: 'براعم',
        },
        {
          value: 1,
          label: 'براعم  واشبال',
        },
        {
          value: 2,
          label: 'اشبال',
        },
        {
          value: 3,
          label: 'كشافه',
        },
        {
          value: 4,
          label: 'كشافه و جواله',
        },
        {
          value: 5,
          label: ' جواله',
        },
      ],
      placeholder: 'الفئة',
      rules: [
        {
          required: true,
        },
      ],
    },
  
    Category: {
      type: 'select',
      label: 'جنس الفئه',
      options: [
        {
          value: 0,
          label: 'اخوة',
        },
        {
          value: 1,
          label: 'اخوات',
        },
      ],
      placeholder: 'الفئة',
      rules: [
        {
          required: true,
        },
      ],
    },
    
      Team:{
    
        type: 'select',
        label: 'الفوج او الفرقه',
        options: [],
        placeholder: 'نوع الفوج او الفرقه',
        rules: [
          {
            required: true,
          },
        ],
      },
      Date:{
        type: 'date',
        label: 'التاريخ',
        options: undefined,
        placeholder: 'التاريخ',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfAunser:{
        type: 'number',
        label: 'عدد  العناصر',
        options: undefined,
        placeholder: 'عدد  العناصر',
        rules: [
          {
            required: true,
          },
        ],
      },
      numberOfBurum:{
        type: 'number',
        label: 'عدد  البراعم',
        options: undefined,
        placeholder: 'عدد  البراعم',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfAshbal: {
        type: 'number',
        label: 'عدد  الاشبال',
        options: undefined,
        placeholder: 'عدد  الاشبال',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfKashafa:{
        type: 'number',
        label: 'عدد  الكشافه',
        options: undefined,
        placeholder: 'عدد  الكشافه',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfJuala:{
        type: 'number',
        label: 'عدد  الجواله',
        options: undefined,
        placeholder: 'عدد  الجواله',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfLeaders:{
        type: 'number',
        label: 'عدد  القاده',
        options: undefined,
        placeholder: 'عدد  القاده',
        rules: [
          {
            required: true,
          },
        ],
      },
      NumberOfCoLeaders:{
        type: 'number',
        label: 'عدد مساعدين القاده',
        options: undefined,
        placeholder: 'عدد مساعدين القاده',
        rules: [
          {
            required: true,
          },
        ],
      },

      NumberOfPeople:{
        type: 'number',
        label: 'عدد المشاركين',
        options: undefined,
        placeholder: 'عدد المشاركين',
        rules: [
          {
            required: true,
          },
        ],
      },



      PositiveSides:{
        type: 'area',
        label: 'النقاط الايجابيه',
        options: undefined,
        placeholder:'نقاط المقررات',
       
      },
      Notes:{
        type: 'area',
        label: 'ملاحظات عامه',
        options: undefined,
        placeholder: 'ملاحظات عامه',
       
      },
    
    

}




export const POIINTS_KEYS:any = {
  PositiveSides:'PositiveSides'
}