import { StringLiteralLike } from "typescript";

export type IMUHAFAZATtype  = {
    
    data:any;
    loading:boolean;
    error:null|string,
    selectedId:string|null

}

export interface IPlaceType {
    id:string;
    name:string;
    parent:string;
    type:MuhafazaType
}


export interface IFaraq {
    id:string;
    name:string;
    forMuhafaza:string;
}
export enum  MuhafazaType {
    MUHAFAZA,
    MANTAKA
}

export type IMANATIKtype  = {
    
    data:any;
    loading:boolean;
    error:null|string}


    
export type IFARAQtype  = {
    
    data:any;
    loading:boolean;
    error:null|string;
}
  