import { HistoryOutlined, MenuOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import React from "react";
import { FC } from "react";
import { HistoryPath } from "../../../Routes/constants";
import { FlexColumn, FlexRow } from "../../../theme/styled";
import { goTo } from "../../../utils";
import IconUsers from "../../SvgIcons/icon-users";
import {  StyledContainer, SubTitle, Tab, Title } from "./styled";
import { Props } from "./types";

const items = [
  { label: (<Tab onClick={()=> goTo(HistoryPath)}>  
  الحركات
  <Space />
  <HistoryOutlined />
   </Tab>), key: "item-1" }, // remember to pass the key prop
  
];

const MenuHeader: FC<Props> = ({}) => {
  return (
    <StyledContainer>
      <Dropdown menu={{ items }}>
     
        <MenuOutlined color="#000"  size={30}  />
   
      </Dropdown>
    </StyledContainer>
  );
};

export default MenuHeader;
