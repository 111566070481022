import { combineReducers } from "redux";
import general from '../store/general/reducers'
import kashaf from '../store/Kashafa/reducers'
import Jalsat from '../store/Jalasat/reducers'
import visits from '../store/Visits/reducers'
import activity from '../store/Activity/reducers'
import history from  '../store/History/reducers'



const rootReducer = combineReducers({
    general,
    kashaf,
    Jalsat,
    visits,
    activity,
    history
});


export default rootReducer;