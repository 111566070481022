import React from "react";

function IconUsers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.695"
      height="35.695"
      x="0"
      y="0"
      enableBackground="new 0 0 35.695 35.695"
      version="1.1"
      viewBox="0 0 35.695 35.695"
      xmlSpace="preserve"
    >
      <path d="M11.558 10.767a6.29 6.29 0 1112.578 0 6.287 6.287 0 01-6.289 6.29 6.288 6.288 0 01-6.289-6.29zm24.109 11.84l-.879-5.27c-.158-.954-.961-1.754-1.896-1.984a4.714 4.714 0 01-3.136 1.191c-1.203 0-2.3-.452-3.135-1.191-.938.229-1.739 1.03-1.897 1.984l-.021.124a4.149 4.149 0 00-1.868-1.052 7.502 7.502 0 01-9.974 0 4.149 4.149 0 00-1.868 1.052l-.021-.124c-.158-.954-.962-1.754-1.897-1.984a4.719 4.719 0 01-6.271 0c-.936.229-1.738 1.03-1.896 1.984l-.879 5.27c-.189 1.131.596 2.057 1.741 2.057h7.222l-.548 3.283c-.3 1.799.948 3.271 2.771 3.271H24.48c1.823 0 3.071-1.475 2.771-3.271l-.548-3.283h7.222c1.146-.002 1.93-.926 1.742-2.057zm-5.912-6.845a3.954 3.954 0 100-7.907 3.954 3.954 0 000 7.907zm-23.817 0a3.953 3.953 0 100-7.907 3.953 3.953 0 000 7.907z"></path>
    </svg>
  );
}

export default IconUsers;
