export const BASE_URI = 'http://194.233.163.50:4040/'
export const API_KEY = 'x-API_KEY'
export const API_KEY_VAL = 'hello-this-is-api-key-u-human-being'

export const ADD_KASHAF_ENPOIND = 'Kader/ADDKadder'
export const ADD_JALASAT = 'Jalasat/AddJalsa'
export const ADD_VISIT_ENDPOINT = 'Visit/AddJalsa'
export const ADD_ACTIVITY_ENDPOINT = 'Activity/AddAcivity'

export const GET_MUHAFAZAT_ENDPOINT = 'Estmara/GetAllMuhafazat'

export const GET_MANATIK_ENDPOINT = 'GET_MANATIK_ENDPOINT'

export const GET_FARAQ_ENDPOINT = 'Estmara/GetAllFaraqByNuhafaza'

export const GET_HISTORY_ENDPOINT = 'Audit/GetHistory'
