import styled  from 'styled-components';


export const ContainerStyled = styled.div`

.ant-col.ant-form-item-label {
    direction: rtl;
    text-align: revert;
}
`
