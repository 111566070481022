import { FormInstance, Spin } from "antd";
import { connect } from "http2";
import React, { FC, useEffect, useState } from "react";
import Forma from "../../Components/Form/Forma";
import SelectFormField from "../../Components/Form/SelectFormElement/Index";
import SubmitButton from "../../Components/Form/SubmitButton/Index";
import MobileLayout from "../../Components/Mobile/MobileLayout";
import {
  _onEnterPointsNotes,
  _resetPointsFromSchema,
  _submitAfterValidate,
  _updateMuhafazatInitOption,
} from "../../Helpers/formHelpers";
import { validateMessages } from "../Kashafa/Constants";

import connected from "./connects";
import { HIDDEN_FIELD_KEY, JalasatSchema, POIINTS_KEYS } from "./Constants";
import { IJalasatInfo, Props } from "./Types";

const JalasatContainer: FC<Props> = ({
  data,
  loading,
  error,
  addJalsaAction,
  addJalsaResetAction,
  getFaraqAction,
  getManatikAction,
  getMuhafazatAction,
  getMuhafazatSelectIdAction,
  mugafazat,
  muhafazatLoading,
  muhfazatError,
  faraq,
  faraqError,
  faraqLoading,
  manatik,
  manatikLoading,
  mantakaError,
}) => {

  const [formData, setFormData] = useState<any>({});
  const [notePoints, setNotePoints] = useState<any>({});
  const [schema, setSchema] = useState<IJalasatInfo | any>(JalasatSchema);
  const [loadForm,setLoadForm] = useState<boolean>(true)
  const formRef = React.createRef<FormInstance>();

  const onSubmit = (values: any) => {
    _submitAfterValidate(
      values,
      Object.keys(POIINTS_KEYS),
      addJalsaAction,
      notePoints
    );
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("errorInfo => ", errorInfo);
  };
  const onSelectChange = (value: any, option: any[], key: string) => {
    _keyChangeHandlerForSelect(key, value);
  };

  const _keyChangeHandlerForSelect = (key: string, value: any) => {

    switch (key) {
      case "City":
        getMuhafazatSelectIdAction(value);
        getManatikAction(value);
        break;
      case "State":
        getFaraqAction(value);
        break;
      case "JalasatTypes":
        if (value === 5) {
          _showOtherOrHide(HIDDEN_FIELD_KEY, false);
        } else {
          _showOtherOrHide(HIDDEN_FIELD_KEY, true);
        }

        break;
      default:
        break;
    }
  };

  const _showOtherOrHide = (key: string, hid: boolean) => {
    const textProps = { hidden: hid }
    setSchema((jalsa: any) => ({
      ...jalsa,
      [key]: { ...jalsa[key], textProps  },
    }));
  };

  const _resetAfterSubmit = () => {
    addJalsaResetAction();
    setNotePoints({});
    formRef.current!.resetFields();
    _resetPointsFromSchema(Object.keys(POIINTS_KEYS), setSchema);
  };
  useEffect(() => {
    // success adding the kashaf onlineπ
    if (data) {
      _resetAfterSubmit();
    }
  }, [data]);

  const _onEnterNotes = (val: any, key: string) => {
    _onEnterPointsNotes(
      val,
      key,
      Object.keys(POIINTS_KEYS),
      setSchema,
      setNotePoints
    );
  };

  useEffect(() => {
    // fetching the muhafazat at first
    getMuhafazatAction();
  }, [getMuhafazatAction]);

  useEffect(() => {
    if (mugafazat) {
      _updateMuhafazatInitOption("City", mugafazat, schema, setSchema);
    }
  }, [mugafazat]);

  useEffect(() => {
    if (manatik) {
      _updateMuhafazatInitOption("State", manatik, schema, setSchema);
    }
  }, [manatik]);

  useEffect(() => {
    if (faraq) {
      _updateMuhafazatInitOption("Team", faraq, schema, setSchema);
    }
  }, [faraq]);
  const keysForm = Object.keys(schema);

  console.log("schema =>",schema)
  return (
    <div>
      <MobileLayout
        title="الجلسات"
        error={faraqError || muhfazatError || error || mantakaError}
        success={Boolean(data)}
        onSuccess={_resetAfterSubmit}
        back
      >
        {loading && "loading sending data to backend"}
        <Forma
          initialValues={formData}
          onSubmit={onSubmit}
          validateMessages={validateMessages}
          onSubmitFailed={onSubmitFailed}
          name="vertical"
          formRef={formRef}
          autoComplete
        >
          {keysForm.map((key, ind) => (
            <SelectFormField
              key={key}
              name={key}
              label={schema[key].label}
              type={schema[key].type}
              placeholder={schema[key].placeholder}
              textProps={schema[key].textProps}
              options={schema[key].options}
              rules={schema[key].rules}
              onEnter={_onEnterNotes}
              points={schema[key].points}
              onSelectChange={onSelectChange}
              loading={muhafazatLoading || manatikLoading || faraqLoading}
            />
          ))}

          <SubmitButton title="حفظ" isSubmit={loading} />
          {loading && <Spin size="large" />}
        </Forma>
      </MobileLayout>
    </div>
  );
};

export default connected(JalasatContainer);
