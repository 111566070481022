import { Form, FormInstance } from "antd";
import React, { FC, useEffect, useState } from "react";
import Forma from "../../Components/Form/Forma";
import SelectFormField from "../../Components/Form/SelectFormElement/Index";
import SubmitButton from "../../Components/Form/SubmitButton/Index";
import MobileLayout from "../../Components/Mobile/MobileLayout";
import { _showOtherOrHide } from "../../Helpers/formHelpers";
import { initForm } from "../../Hooks/Forms";

import connected from "./connects";
import { BasiInfoKashafSchema, validateMessages } from "./Constants";
import { IBasicInfoSchema, Props } from "./Types";

// TODO fix the forma
/// TODO fix the date to get accepted from backenf
// linke with backend

const AddKashafa: FC<Props> = ({
  addKashafAction,
  data,
  loading,
  error,
  addKashafResetAction,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [schema, setSchema] = useState<IBasicInfoSchema | any>(
    BasiInfoKashafSchema
  );
  const formRef = React.createRef<FormInstance>();

  const onSubmit = (values: any) => {
    const BirthDate = values["BirthDate"].format();
    const JoinDate = values["JoinDate"].format();
    const newValues = { ...values, BirthDate, JoinDate };
    addKashafAction(newValues);
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("errorInfo => ", errorInfo);
  };
  const onSelectChange = (value: any, option: any[], key: string) => {
    _keyChangeHandlerForSelect(key, value);
  };

  const _keyChangeHandlerForSelect = (key: string, value: any) => {
    switch (key) {
      case "HealthStatus":
        changeStatusOfSHowAndHide(value, "OtherHealthStatus", 4);
        break;
      case "KaderType":
        changeStatusOfSHowAndHide(value, "KaderTypeInfo", 2);
        break;

      default:
        break;
    }
  };

  const changeStatusOfSHowAndHide = (
    value: any,
    key: string,
    condition: number
  ) => {
  
    let status = true;
    if (value === condition) status = false;
    _showOtherOrHide(key, status, setSchema);
  };

  const _resetAfterSubmit = () => {
    addKashafResetAction();
    formRef.current!.resetFields();
  };
  useEffect(() => {
    // success adding the kashaf onlineπ
    if (data) {
      _resetAfterSubmit();
    }
  }, [data]);

  const keysForm = Object.keys(BasiInfoKashafSchema);
  return (
    <div>
      <MobileLayout
        title="اضافه كشاف جديد"
        error={error}
        success={Boolean(data)}
        onSuccess={_resetAfterSubmit}
        back
      >
        {loading && "loading sending data to backend"}
        <Forma
          initialValues={formData}
          onSubmit={onSubmit}
          validateMessages={validateMessages}
          onSubmitFailed={onSubmitFailed}
          name="vertical"
          autoComplete
          formRef={formRef}
        >
          {keysForm.map((key, ind) => (
            <SelectFormField
              key={key}
              label={schema[key].label}
              type={schema[key].type}
              placeholder={schema[key].placeholder}
              name={key}
              options={schema[key].options}
              textProps={schema[key].textProps}
              rules={schema[key].rules}
              onEnter={undefined}
              onSelectChange={onSelectChange}
            />
          ))}

          <SubmitButton title="حفظ" isSubmit={loading} />
        </Forma>
      </MobileLayout>
    </div>
  );
};

export default connected(AddKashafa);
