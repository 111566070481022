import { IVisits } from './types';


export const addVisitLoadingSelector = (state:IVisits) => {
    if(!state ) return false
    return state.loading
}
export const addVisitErrorSelector = (state:IVisits) => {
    return state.error
}


export const addVisitDataSelector = (state:IVisits) => {
    if(!state || !state.data) return null
    return state.data
}

