import { CHECK_IS_LOGEDIN, LOG_OUT } from './../global/actionTypes';
import { CHECK_AUTH, LOGIN, LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS, SUCESS_CHECK_AUTH } from "../global/actionTypes"


export const checkAuthAction = () => {
    return {
        
        type:CHECK_AUTH
    
    }
}

export const checkIsLogedInAction = () => {
    return {
        
        type:CHECK_IS_LOGEDIN
    
    }
}


export const loginAction = (params:any) => {
    return {
        type:LOGIN,
        params
    }
}


export const loginStartAction = () => {
    return {
        type:LOGIN_START,
        
    }
}


export const loginSuccessAction = (payload:any) => {
    return {
        type:LOGIN_SUCCESS,
        payload
    }
}

export const loginFailAction = (error:string) => {
    return {
        type:LOGIN_FAIL,
        error
    }
}

export const successAuthAction = (payload:any) =>{
    return {
        type:SUCESS_CHECK_AUTH,
        payload
    }
}

export const logoutAction = () =>{
    return {
        type:LOG_OUT,
        
    }
}