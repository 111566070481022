import { IDataResponse } from './../general/types';
import { issetResponseResult } from './../global/respones';
import { NO_DATA } from './../../Common/errors'
import {
  addJalsaFailAction,
  addJalsaStartAction,
  addJalsaSuccessAction,
} from './actions'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ADD_JALASA } from './../global/actionTypes'
import { addJalasatApi } from './api'
import { ISagaData } from '../global/types'
import { SERVER_ERROR } from '../../Common/errors'
import { AxiosResponse } from 'axios'

function* addJalsaSaga(data: ISagaData) {
  try {
    yield put(addJalsaStartAction())
    const response: AxiosResponse<IDataResponse<string>> = yield call(addJalasatApi, data.params)
    if (issetResponseResult(response)) {
      yield put(addJalsaSuccessAction(response.data.result))
    } else {
      yield put(addJalsaFailAction(NO_DATA))
    }
  } catch (e) {
    yield put(addJalsaFailAction(SERVER_ERROR))
  }
}

function* kashafaSaga() {
  yield all([takeLatest(ADD_JALASA, addJalsaSaga)])
}

export default kashafaSaga
