import { ADD_KASHAF, ADD_KASHAF_START, ADD_KASHAF_FAIL, ADD_KASHAF_SUCCESS, ADD_KASHAF_RESET, ADD_KASHAF_OFFLINE } from './../global/actionTypes';


export const addKashafAction = (params:any) => {
    return {
        type:ADD_KASHAF,
        params
    }
} 

export const addKashafStartAction = () => {
    return {
        type: ADD_KASHAF_START,
    }
}

export const addKashafSuccessAction = (payload:any) => {
    return {
        type:ADD_KASHAF_SUCCESS ,
        payload
    }
}


export const addKashafFailAction = (error:any) => {
    return {
        type: ADD_KASHAF_FAIL,
        error
    }
}



export const addKashafResetAction = () => {
    return {
        type: ADD_KASHAF_RESET
    }
}


export const addKashafOfflineAction = (payload:any) => {
    return {
        type: ADD_KASHAF_OFFLINE,
        payload
    }
}

