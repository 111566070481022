export const LoginPath = '/login'
export const FlightPath = '/flights'
export const AddKashaf = '/Add_kashaf'
export const AddJalsa = '/add_Jalasat'
export const AddVisits = '/add_Visits'
export const AddActivity= '/add_Activity'
export const NetwrokErrorPath = "/network_error"
export const HistoryPath = "/history"



export const HomePath = '/home'


export const AllPages =[
    "login",
    "Add_kashaf",
    "add_Jalasat",
    "add_Visits",
    "add_Activity",
    "network_error",
    "history"
]

