import * as Yup from 'yup';


export const initForm = (formSchema:any) => {
    console.log("iniForm",formSchema)
    let _formData:any = {};
    let _validationSchema:any = {};

    for(var key of Object.keys(formSchema)){
        _formData[key] = "";

        if(formSchema[key].type === "text"){
            _validationSchema[key] = Yup.string();
        }else if(formSchema[key].type === "email"){
            _validationSchema[key] = Yup.string().email()
        }else if(formSchema[key].type === "select"){
            _validationSchema[key] = Yup.string().oneOf(formSchema[key].options.map((o:any) => o.value));
        }

        if(formSchema[key].required){
            _validationSchema[key] = _validationSchema[key].required('Required');
        }
    }

    return [_formData,_validationSchema]
}