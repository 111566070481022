import React, { FC } from "react";
import { Props } from "./types";
import { StyledContainer } from "./styled";
import { Form, FormInstance } from "antd";

const Forma: FC<Props> = ({
  initialValues,
  onSubmit,
  children,
  validateMessages,
  layout,
  name,
  onSubmitFailed,
  autoComplete,
  formRef
}) => {




  return (
    <StyledContainer>
      <Form
        onFinish={onSubmit}
        ref={formRef}
        name={name}
        initialValues={initialValues}
        validateMessages={validateMessages}
        onFinishFailed={onSubmitFailed}
        autoComplete={autoComplete ? "on" : "off"}
        {...layout}
        labelAlign="right"
        
      >
        {children}
      </Form>
    </StyledContainer>
  );
};

export default Forma;
