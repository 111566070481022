import { IMANATIKtype } from './types';
import { IDataResponse } from './../general/types';
import { NO_AFOUAJ, NO_DATA } from './../../Common/errors';
import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import {  getFaraqApi, getManatikApi, getMuhafazatApi } from './api';
import { ISagaData } from '../global/types';
import { issetResponse, issetResponseResult } from '../global/respones';
import { SERVER_ERROR } from '../../Common/errors';
import { AxiosResponse } from 'axios';
import { getMuhafazatStartAction, getMuhafazatSuccessAction, getMuhafazatFailAction, getManatikSuccessAction, getManatikStartAction, getManatikFailAction , getFaraqSuccessAction, getFaraqStartAction, getFaraqFailAction } from './actions';
import { GET_FARAQ, GET_MANATIK ,GET_MUHAFAZAT, GET_MUHAFAZAT_SELECT } from './../global/actionTypes';
import { getMuhafazatIdSelector } from './selectors';


 


function* getMuhafazatSaga(data:ISagaData) {
    try {
        yield put(getMuhafazatStartAction())
        
      const response:AxiosResponse = yield call(getMuhafazatApi);
      if(issetResponseResult(response)){
        yield put(getMuhafazatSuccessAction(response.data.result))
        
      }else{
        yield put(getMuhafazatFailAction(NO_DATA));

        }

    } catch (e) {

      yield put(getMuhafazatFailAction(SERVER_ERROR));
    }
  }






function* getManatikSaga(data:ISagaData) {
    try {
        yield put(getManatikStartAction())
        

     
      const response:AxiosResponse<IDataResponse<any>> = yield call(getManatikApi,data.params);

      if(issetResponseResult(response)){
        yield put(getManatikSuccessAction(response.data.result))
        
      }else{

        
        yield put(getManatikFailAction(NO_DATA));

        }

    } catch (e) {
      yield put(getManatikFailAction(SERVER_ERROR));
    }
  }





function* getFaraqSaga(data:ISagaData) {
    try {
        yield put(getFaraqStartAction())
        


      const response:AxiosResponse = yield call(getFaraqApi,data.params);

      if(issetResponseResult(response)){
        yield put(getFaraqSuccessAction(response.data.result))
        
      }else{

        yield put(getFaraqFailAction(NO_AFOUAJ));

        }

    } catch (e) {
      yield put(getFaraqFailAction(SERVER_ERROR));
    }
  }




function* selectMuhafazatId(data:ISagaData){

  try {
      
  } catch (error) {
    
  }
}



 function* kashafaSaga() {
  yield all([takeLatest(GET_MANATIK, getManatikSaga)]);
  yield all([takeLatest(GET_MUHAFAZAT, getMuhafazatSaga)]);
  yield all([takeLatest(GET_FARAQ, getFaraqSaga)]);
  //yield all([takeLatest(GET_MUHAFAZAT_SELECT,selectMuhafazatId)])


}

  export default kashafaSaga