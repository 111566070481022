

export const theme = {
    colors:{
        primary:"#00b5adf2",
        headerLabel:"#fff",
        bluePrimary:"#2c3e50",
        blueAsphelt:"#34495e",
        silver:"#bdc3c7",
        gray:"#ecf0f1",
        carrot:"#e67e22",
        cardColor:"#d38b95",
        grey:"gray",
        background:"#ededed29",
        disableButton:"#afd4d2f2"
    },
    fontSizes:{
        title:"3vh",
        subTitle:"1.5vh"
    }
  };
  

  