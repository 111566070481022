import {  ADD_VISIT_ENDPOINT } from './../../Config/Constants';
import { axiosInstance } from "../../Config/api"


export const addVisittApi:any = async (params:any) => {

    try {
        var resp = await axiosInstance.post(ADD_VISIT_ENDPOINT,params)
      
        return resp;
    } catch (error) {
        return error;
    }

}

