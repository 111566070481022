import { Input, Space } from "antd";
import React, { FC, useEffect, useState } from "react";
import ButtonComponent from "../../Components/Button";
import { IsMobile } from "../../Hooks/Mobile";
import connected from "./connects";
import { ErrorStyled, LoginContent, StyledContainer } from "./styled";
import { Props } from "./types";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import { LStorage } from "../../Config/singleton";
import { redirect } from "react-router-dom";
import { HomePath } from "../../Routes/constants";
import { goTo, isRememberMeData } from "../../utils";
import CheckBoxCust from "../../Components/Form/CheckBox/Index";
import { REMEMBER } from "../../Common/Constants";
import Password from "antd/lib/input/Password";

const initLogin = { email: "", password: "" };

const Login: FC<Props> = ({
  loginAction,
  loading,
  error,
  loginFailAction,
  checkIsLogedInAction,
}) => {
  const [credintials, setCredentials] = useState<any>(initLogin);
    const [check,setCheck] = useState<boolean>(false);
  const _validateInput = (): boolean => {
  
    if (
      Boolean(credintials.email&&credintials.email.trim()) &&
      Boolean(credintials.password&&credintials.password.trim())
    ) {
        loginFailAction('');
      return true;
    }
    loginFailAction("ادخل المعلومات رجاءا");
    return false;
  };

  const login = () => {
    if (_validateInput()) {
      loginAction(credintials);
    }
  };

  const handleChange = (e: any) => {
    setCredentials((credintials: any) => ({
      ...credintials,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheck = (e:any) => {
      /// set remember me i lcal storage
    LStorage.SetByKey(REMEMBER,e.target.checked)
   
    setCheck(e=>!e)
  }
  // TODO remember me prev statte
  // TODO password fix
  //     const IsRememberMe: IStorageType = LStorage.GetByKey(REMEMBER)

  useEffect(() => {
    const str = isRememberMeData();
    if(str){
        setCredentials((cred:any)=>({...cred,password:str.PWD,email:str.email}) );
        setCheck(true)
    };

  }, []);


  const buttomLoad = loading ? " انتظر لطفا..." : "التسجيل";
  return (
    <StyledContainer>
      <div>
        <LoginContent>
          <h1>تسجيل الدخول </h1>
          <Space size={12} direction="vertical">
            <Input
              value={credintials["email"]}
              name="email"
              size="large"
              onChange={handleChange}
              placeholder="البريد الالكتروني"
              status={Boolean(error != null) ? "error" : ""}
            />

            <Input.Password
              value={credintials["password"]}
              name="password"
              size="large"
              onChange={handleChange}
              placeholder="الرمز السري"
              status={Boolean(error != null) ? "error" : ""}
            />


            <CheckBoxCust onCheck={handleCheck} label={"تذكرني"} checked={check} />
            <ButtonComponent  title={buttomLoad} clickButton={login}  wait={loading}/>
          </Space>
          {error && <ErrorStyled>{error}</ErrorStyled>}
        </LoginContent>
      </div>
    </StyledContainer>
  );
};

export default connected(Login);
