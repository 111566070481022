import React from "react";

function IconShutDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98.397"
      height="98.396"
      x="0"
      y="0"
      enableBackground="new 0 0 98.397 98.396"
      version="1.1"
      viewBox="0 0 98.397 98.396"
      xmlSpace="preserve"
    >
      <path d="M66.573 9.146a5.967 5.967 0 00-2.25-.44 5.974 5.974 0 00-5.562 3.749 6.006 6.006 0 003.309 7.813c13.028 5.276 21.446 17.764 21.446 31.812 0 18.923-15.396 34.317-34.317 34.317-18.922 0-34.317-15.395-34.317-34.317 0-14.044 8.418-26.529 21.446-31.805a5.964 5.964 0 003.272-3.221 5.959 5.959 0 00.036-4.592 5.976 5.976 0 00-5.563-3.749c-.772 0-1.529.148-2.25.44-17.581 7.12-28.941 23.97-28.941 42.927 0 25.539 20.777 46.317 46.317 46.317 25.541 0 46.317-20.778 46.317-46.317-.001-18.962-11.361-35.815-28.943-42.934z"></path>
      <path d="M49.198 42.698a5 5 0 005-5V5a5 5 0 10-10 0v32.698a5 5 0 005 5z"></path>
    </svg>
  );
}

export default IconShutDown;
