import { issetResponseResult } from './../global/respones';
import { NO_DATA } from './../../Common/errors';
import { addKashafStartAction, addKashafSuccessAction, addKashafFailAction, addKashafOfflineAction } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_KASHAF, ADD_KASHAF_OFFLINE } from './../global/actionTypes';
import { addKashafApi } from './api';
import { ISagaData } from '../global/types';
import { issetResponse } from '../global/respones';
import { SERVER_ERROR } from '../../Common/errors';
import { AxiosResponse } from 'axios';



function* addKashafSaga(data:ISagaData) {
    try {
        yield put(addKashafStartAction())
        

        // DONE add the kashafOffline before send to backend
        // we change that that all user gets from 
        //yield put(addKashafOfflineAction(data.params))
      yield put(addKashafStartAction())

      const response:AxiosResponse = yield call(addKashafApi,data.params);

      if(issetResponseResult(response)){
        yield put(addKashafSuccessAction(response.data.result))
        
      }else{

        yield put(addKashafFailAction(NO_DATA));

        }

    } catch (e) {
      yield put(addKashafFailAction(SERVER_ERROR));
    }
  }

// implement adding saga ooffline
function* addKashafOfflineSaga(data:ISagaData){

    // prapre data store to store the kashaf info

    // create user id 

    // store them

}


 function* kashafaSaga() {
    yield all([takeLatest(ADD_KASHAF, addKashafSaga)]);
    yield all([takeLatest(ADD_KASHAF_OFFLINE, addKashafOfflineSaga)]);


  }

  export default kashafaSaga