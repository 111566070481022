import {
  ADD_JALASA,
  ADD_JALASA_FAIL,
  ADD_JALASA_RESET,
  ADD_JALASA_START,
  ADD_JALASA_SUCCESS,
} from '../global/actionTypes'

export const addJalsaAction = (params: any) => {
  return {
    type: ADD_JALASA,
    params,
  }
}

export const addJalsaStartAction = () => {
  return {
    type: ADD_JALASA_START
  }
}

export const addJalsaSuccessAction = (payload: any) => {
  return {
    type: ADD_JALASA_SUCCESS,
    payload
  }
}

export const addJalsaFailAction = (error: any) => {
  return {
    type: ADD_JALASA_FAIL,
    error
  }
}

export const addJalsaResetAction = () => {
  return {
    type: ADD_JALASA_RESET
  }
}
