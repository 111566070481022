import styled from 'styled-components'
import { IPropTheme } from '../../../../Common/types'

export const StyledContainer = styled.div`
  width: 100%;
  background: ${(props: IPropTheme) => props.theme.colors.primary};
  color: ${(props: IPropTheme) => props.theme.colors.headerLabel};
  height: 8vh;
  box-shadow: 0px 0px 11px grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
`

export const BackIcon = styled.div``
export const Title = styled.div`
  font-weight: bold;
  font-size: 25px;
`
export const Logo = styled.div`
  svg {
    width: 34px;
    fill: white;
    height: 30px;
  }
`

export const LogoutMessageStyled = styled.div`
  direction: rtl;
`
