import { Form, Select } from "antd";
import React, { FC } from "react";
import { IOption } from "../../../Containers/Kashafa/Types";
import { ContainerStyled, ErrorStyled } from "./styled";
import { Props } from "./types";

const SelectField: FC<Props> = ({
  name,
  label,
  options,
  placeholder,
  onSelectChange,
  rules,
  loading,
  mode,
  hidden
}) => {
  const { Option } = Select;

  const _onSelectCHange = (value: any, options: any) => {
    if (onSelectChange) {
      onSelectChange(value, options, name);
    }
  };

  return (
    <ContainerStyled className={`field `}>
      {!hidden &&
      <Form.Item name={name} label={label} rules={rules}>
        <Select
          placeholder={placeholder}
          onChange={_onSelectCHange}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          loading={loading}
          mode={mode}
          showSearch
          allowClear
        >
          {options &&
            options.map((opt: IOption) => (
              <Option value={opt.value}>{opt.label}</Option>
              ))}
        </Select>
      </Form.Item>
            }
    </ContainerStyled>
  );
};

export default SelectField;
