import { IActivity } from './types';


export const addActivityLoadingSelector = (state:IActivity) => {
    if(!state ) return false
    return state.loading
}
export const addActivityErrorSelector = (state:IActivity) => {
    return state.error
}


export const addActivityDataSelector = (state:IActivity) => {
    if(!state || !state.data) return null
    return state.data
}

