import { IDataResponse } from "../general/types"
import { 
        GET_MUHAFAZAT, GET_MUHAFAZAT_SUCCESS,GET_MUHAFAZAT_FAIL,GET_MUHAFAZAT_START,GET_MUHAFAZAT_RESET ,
        GET_MANATIK, GET_MANATIK_SUCCESS,GET_MANATIK_FAIL,GET_MANATIK_START,GET_MANATIK_RESET  ,
     GET_FARAQ, GET_FARAQ_SUCCESS,GET_FARAQ_FAIL,GET_FARAQ_START,GET_FARAQ_RESET, GET_MUHAFAZAT_SELECT  } from "../global/actionTypes"


export const getMuhafazatAction   = () => {
    return {
        type:GET_MUHAFAZAT
    }
} 

export const getMuhafazatSelectIdAction   = (payload:string) => {
    return {
        type:GET_MUHAFAZAT_SELECT,
        payload,
    }
} 

export const getMuhafazatStartAction = () => {
    return {
        type: GET_MUHAFAZAT_START,
    }
}

export const  getMuhafazatSuccessAction = (payload:any) => {
    return {
        type:GET_MUHAFAZAT_SUCCESS ,
        payload
    }
}


export const  getMuhafazatFailAction = (error:any) => {
    return {
        type:GET_MUHAFAZAT_FAIL,
        error
    }
}



export const  getMuhafazatResetAction = () => {
    return {
        type: GET_MUHAFAZAT_RESET
    }
}





export const getManatikAction   = (params:any) => {
    return {
        type:GET_MANATIK,
        params
    }
} 

export const getManatikStartAction = () => {
    return {
        type: GET_MANATIK_START,
    }
}

export const  getManatikSuccessAction = (payload:IDataResponse<any>) => {
    return {
        type:GET_MANATIK_SUCCESS ,
        payload
    }
}


export const  getManatikFailAction = (error:any) => {
    return {
        type:GET_MANATIK_FAIL,
        error
    }
}



export const  getManatikResetAction = () => {
    return {
        type: GET_MANATIK_RESET
    }
}





export const getFaraqAction   = (params:string) => {
    return {
        type:GET_FARAQ,
        params
    }
} 

export const getFaraqStartAction = () => {
    return {
        type: GET_FARAQ_START,
    }
}

export const  getFaraqSuccessAction = (payload:any) => {
    return {
        type:GET_FARAQ_SUCCESS ,
        payload
    }
}


export const  getFaraqFailAction = (error:any) => {
    return {
        type:GET_FARAQ_FAIL,
        error
    }
}



export const  getFaraqResetAction = () => {
    return {
        type: GET_FARAQ_RESET
    }
}


