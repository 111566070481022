import {
  ADD_JALASA_START,
  ADD_JALASA_FAIL,
  ADD_JALASA_RESET,
  ADD_JALASA_SUCCESS,
} from './../global/actionTypes'
import produce, { Draft } from 'immer'
import { IJalasa } from './types'

const initialState: IJalasa = {
  data: null,
  loading: false,
  error: null,
}

const addJalsaStartReducers = (state: IJalasa) => {
  return produce(state, (draft: Draft<IJalasa>) => {
    draft.data = null
    draft.error = null
    draft.loading = true
  })
}

const addJalsaSuccessReducers = (state: IJalasa, action: any) => {
  return produce(state, (draft: Draft<IJalasa>) => {
    draft.error = null
    draft.loading = false
    draft.data = action.payload
  })
}

const addJalsaFailReducers = (state: IJalasa, action: any) => {
  return produce(state, (draft: Draft<IJalasa>) => {
    draft.error = action.error
    draft.loading = false
  })
}

const addJalsaResetReducers = (state: IJalasa) => {
  return produce(state, (draft: Draft<IJalasa>) => {
    draft.error = null
    draft.loading = false
    draft.data = null
  })
}

function JalsaReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_JALASA_START:
      return addJalsaStartReducers(state)
    case ADD_JALASA_SUCCESS:
      return addJalsaSuccessReducers(state, action)
    case ADD_JALASA_FAIL:
      return addJalsaFailReducers(state, action)
    case ADD_JALASA_RESET:
      return addJalsaResetReducers(state)

    default:
      return state
  }
}

export default JalsaReducer
