import React from "react";
import { FC } from "react";
import { ContainerStyled } from "./styled";
import { Props } from "./types";
import { Button, Checkbox, Form } from "antd";

const CheckBoxCust: FC<Props> = ({ label, onCheck,checked}) => {
  return (
    <ContainerStyled className="field">
 
      <Checkbox onChange={onCheck} checked={checked}>{label}</Checkbox>
    
    </ContainerStyled>
  );
};

export default CheckBoxCust;
