import { IJalasa } from './types'

export const addJalsaLoadingSelector = (state: IJalasa) => {
  return state.loading
}
export const addJalsaErrorSelector = (state: IJalasa) => {
  return state.error
}

export const addJalsaDataSelector = (state: IJalasa) => {
  if (!state || !state.data) return null
  return state.data
}
