import { resolvePath ,redirect} from "react-router-dom";
import { CREDNTIAL_KEY, REMEMBER } from "../Common/Constants";
import { LStorage } from "../Config/singleton";
import StringEncoding from "../Services/StringEncoding";
import { ILoginData, IStorageType } from "../store/general/types";
// import histo from "history"
export const goTo = (path:string) => {

     //redirect(resolvePath(path).pathname)
  window.location.href=path
   // window.history.

}

export const getLocation = (cb:(pos:any)=>void)=>{
    if('geolocation' in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition((position) => {
                cb(position);
        });
      } else {
        /* geolocation IS NOT available */
      }
}

export const isRememberMeData = ():ILoginData|null => {
  const IsRememberMe: IStorageType = LStorage.GetByKey(REMEMBER)
    if (IsRememberMe=="true") {
      const cred: IStorageType =  LStorage.GetByKey(CREDNTIAL_KEY)

      if (cred) {
        return  StringEncoding.decodeBase64<ILoginData>(
          cred,
        )

       
      }
}
  return null;
}



export const getQueryString = (params:any)=>{
  
  return Object.keys(params).map((str:string) => `${str}=${params[str]}`).join("&");

}