import { ADD_KASHAF_START, ADD_KASHAF_SUCCESS, ADD_KASHAF_FAIL, ADD_KASHAF_RESET } from './../global/actionTypes';
import produce, { Draft } from 'immer';
import { IKashafa } from './types';

const initialState: IKashafa = {
    data:null,
    loading:false,
    error:null,

  };



  const addKashafaStartReducers = (state:IKashafa)=> {

    return  produce(state,(draft:Draft<IKashafa>) => {
          draft.data=null;
          draft.error = null;
          draft.loading = true
      
      })
  
}



const addKashafaSuccessReducers = (state:IKashafa,action:any)=> {

  return  produce(state,(draft:Draft<IKashafa>) => {
        draft.error = null;
        draft.loading = false;
        draft.data = action.payload
    
    })

}

const addKashafaFailReducers = (state:IKashafa,action:any)=> {

  return  produce(state,(draft:Draft<IKashafa>) => {
        draft.error = action.error
        draft.loading = false;
    
    })

}

const addKashafaResetReducers = (state:IKashafa)=> {

  return  produce(state,(draft:Draft<IKashafa>) => {
        draft.error = null
        draft.loading = false;
        draft.data = null
    
    })

}


 function KashafReducer(state = initialState, action: any) {
    switch (action.type) {
     case ADD_KASHAF_START : return addKashafaStartReducers(state);
     case ADD_KASHAF_SUCCESS: return addKashafaSuccessReducers(state,action);
     case ADD_KASHAF_FAIL: return addKashafaFailReducers(state,action)
     case ADD_KASHAF_RESET: return addKashafaResetReducers(state)

      default: return state
    }
  };
  

  export default KashafReducer

