import { ADD_VISIT, ADD_VISIT_START, ADD_VISIT_SUCCESS, ADD_VISIT_FAIL, ADD_VISIT_RESET } from './../global/actionTypes';


export const addVisistAction = (params:any) => {
    return {
        type:ADD_VISIT,
        params
    }
} 

export const addVisistStartAction = () => {
    return {
        type: ADD_VISIT_START,
    }
}

export const addVisistSuccessAction = (payload:any) => {
    return {
        type:ADD_VISIT_SUCCESS ,
        payload
    }
}


export const addVisistFailAction = (error:any) => {
    return {
        type:ADD_VISIT_FAIL,
        error
    }
}



export const addVisistResetAction = () => {
    return {
        type: ADD_VISIT_RESET
    }
}


