import { Timeline } from "antd";
import moment from "moment";
import React, { FC } from "react";
import { COLORS, MESGS } from "../Constants";
import { ITimeLineType } from "../Types";
import { TimeLineStyled } from "./styled";
import { Props } from "./Types";

const TimelineList: FC<Props> = ({ data }) => {
  return (
    <TimeLineStyled>
      <Timeline mode="alternate" >
      {data &&
        data.map((dt: ITimeLineType) => (
          dt.name !=="ScheduleJobs"&&
            <Timeline.Item color={COLORS[dt.name]} key={dt.id}>
              {MESGS[dt.name]} {moment(dt.dateTime).format("LLL")}
            </Timeline.Item>
    
    ))}
    </Timeline>
    </TimeLineStyled>
  );
};

export default TimelineList;
