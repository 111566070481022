import React from "react";
import { FC } from "react";
import { ContainerStyled } from "./styled";
import { Props } from "./types";
import SelectField from "../SelectField/Index";
import TextField from "../TextField/Index";

const SelectFormField: FC<Props> = ({
  type,
  label,
  name,
  value,
  isArabic,
  placeholder,
  icon,
  options,
  rules,
  textProps,
  onSelectChange,
  loading,
  onEnter,
  points
}) => {

  console.log("textProps => ",textProps)
  const Input =
    type === "select" ? (
      <SelectField
        loading={loading}
        placeholder={placeholder}
        rules={rules}
        options={options}
        label={label}
        name={name}
        onSelectChange={onSelectChange}
        hidden={textProps&&textProps.hidden}
        mode={textProps&&textProps.mode}
      />
    ) : (
      <TextField
        onEnter={onEnter}
        hidden={textProps&&textProps.hidden}
        type={type}
        rules={rules}
        label={label}
        name={name}
        value={value}
        isArabic={isArabic}
        placeholder={placeholder}
        icon={icon}
        points={points}
      />
    );

  return <ContainerStyled className="field">{Input}</ContainerStyled>;
};

export default SelectFormField;
