import { connect } from "react-redux"
import { isAuthSelector } from "../store/general/selectors"


  const mapDispatchToProps = {
      
    
}
const mapStateToProps = (state:any) => {
  const general = state.general
 return {
    isAuth:isAuthSelector(general)
}



}
  
const connected = connect(mapStateToProps, mapDispatchToProps)
export default  connected