import { IBasicInfoSchema } from "./Types";

export const BasiInfoKashafSchema: IBasicInfoSchema|any = {

    FirstName: {
        type: "text",
        label: "الاسم الاول",
        options:undefined,
        placeholder:"الاسم الاول",
        rules:[
            {
                required:true
            }
        ]
    

    },
    SecondName: {
        type: "text",
        label: "اسم الاب",
        options:undefined,
        placeholder:"اسم الاب",
        rules:[
            {
                required:true
            }
        ]
    },
    LastName: {
        type: "text",
        label: "اسم الجد",
        options:undefined,
        placeholder:"الاسم الجد",
        rules:[
            {
                required:true
            }
        ]
    },
    
    FourthName: {
        type: "text",
        label: "اسم اب الجد",
        options:undefined,
        placeholder:"الاسم اب الجد"
    },
    FamilyName: {
        type: "text",
        label: "اللقب",
        options:undefined,
        placeholder:"اللقب"
    },
    Gender: {
        type: "select",
        label: "الحنس",
        options:[
            {
                value:0,
                label:"ذكر"
            },
            {
                value:1,
                label:"انثى"
            },
          
        ],
        placeholder:"الجنس ",
        rules:[
            {
                required:true
            }
        ]
    },
    BirthDate:  {
        type: "date",
        label: "تاريخ الميلاد",
        options:undefined,
        placeholder:"تاريخ الميلاد",
        rules:[
            {
                required:true
            }
        ]
    },
    SocialStatus:  {
        type: "select",
        label: "الحاله الاجتماعيه",
        options:[
            {
                value:0,
                label:"اعزب"
            },
            {
                value:1,
                label:"متزوج"
            },
           
        ],
        placeholder:"الحاله الاجتماعيه",
        rules:[
            {
                required:true
            }
        ]
    },
    JoinDate: {
        type: "date",
        label: "تاريخ الالتحاق",
        options:undefined,
        placeholder:"تاريخ الالتحاق"
        ,
        rules:[
            {
                required:true
            }
        ]
    },
    BirthPlace: {
        type: "text",
        label: "مكان الولاده",
        options:undefined,
        placeholder:"مكان الولاده",
        rules:[
            {
                required:true
            }
        ]
    

    },
    KaderType:  {
        type: "select",
        label: "نوع العضو",
        options:[
            {
                value:0,
                label:"حركة"
            },
            {
                value:1,
                label:"لجنه"
            },
            {
                value:2,
                label:"عنصر"
            },
        ],
        placeholder:"نوع العضو",
        rules:[
            {
                required:true
            }
        ]
    },
    // TODO change it in backend to match this change
    KaderTypeInfo:  {
        type: "select",
        label: "نوع العنصر",
        options:[
            {
                value:0,
                label:"براعم"
            },
            {
                value:1,
                label:"اشبال"
            },
            {
                value:2,
                label:"كشاف"
            },
            {
                value:3,
                label:"جوال"
            },
        ],
        placeholder:"نوع العنصر",
     
        rules:[
            {
                required:true
            }
        ],
        textProps:{
            hidden:true
        }
    },
    // PicProfile:  {
    //     type: "text",
    //     label: "الصوره الشخصيه",
    //     options:undefined,
    //     placeholder:"تاريخ الميلاد",
    //     rules:[
    //         {
    //             required:true
    //         }
    //     ]
    // },
    HealthStatus: {
        type: "select",
        label: "الحاله الصحية",
        options:[
            {
                value:0,
                label:"صحيح"
            },
            {
                value:1,
                label:"مريض"
            },
            {
                value:4,
                label:"اخرى "
            },
        ],
        placeholder:"الحاله الصحيه",
        rules:[
            {
                required:true
            }
        ]
    },
    OtherHealthStatus: {
        type: "text",
        label: "الحاله الصحيه",
        options:undefined,
        placeholder:"الحاله الصحيه",
        textProps:{
            hidden:true
        },
        rules:[
            {
                required:true
            }
        ]
    },


    


}


export const validateMessages = {
    required: ' مطلوب ${label}',
    types: {
      email: ' الرجاء ادخل بريد مناسب ${label} ',
      number: ' الرجاء ادخال رقم مناسب ${label}',
    },
    number: {
      range: '${label} الرقم يجب ان يكون بين ${min} و ${max}',
    },
  };