import { connect } from 'react-redux'
import {
  getMuhafazatAction,
  getManatikAction,
  getMuhafazatSelectIdAction,
  getFaraqAction,
} from '../../store/Estmara/actions'
import {
  getMuhafazatSelector,
  getMuhafazatLoadingSelector,
  getMuhafazatErrorSelector,
  getMantakaSelector,
  getMantakaLoadingSelector,
  getMantakaErrorSelector,
  getFaraqSelector,
  getFaraqLoadingSelector,
  getFaraqErrorSelector,
} from '../../store/Estmara/selectors'
import {
  addJalsaAction,
  addJalsaResetAction,
} from '../../store/Jalasat/actions'
import {
  addJalsaDataSelector,
  addJalsaErrorSelector,
  addJalsaLoadingSelector,
} from '../../store/Jalasat/selectors'

const mapDispatchToProps = {
  addJalsaAction,
  addJalsaResetAction,
  getMuhafazatAction,
  getManatikAction,
  getMuhafazatSelectIdAction,
  getFaraqAction,
}

const mapStateToProps = (state: any) => {
  const jalsat = state.Jalsat
  const Muhafazat = state.general.Muhafazat
  const Manatiq = state.general.Manatiq
  const Faraq = state.general.Faraq
  return {
    data: addJalsaDataSelector(jalsat),
    loading: addJalsaLoadingSelector(jalsat),
    error: addJalsaErrorSelector(jalsat),
    mugafazat: getMuhafazatSelector(Muhafazat),
    muhafazatLoading: getMuhafazatLoadingSelector(Muhafazat),
    muhfazatError: getMuhafazatErrorSelector(Muhafazat),
    manatik: getMantakaSelector(Manatiq),
    manatikLoading: getMantakaLoadingSelector(Manatiq),
    mantakaError: getMantakaErrorSelector(Manatiq),
    faraq: getFaraqSelector(Faraq),
    faraqLoading: getFaraqLoadingSelector(Faraq),
    faraqError: getFaraqErrorSelector(Faraq),
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)
export default connected
