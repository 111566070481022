import styled from 'styled-components'
import { IPropTheme } from '../../../Common/types'

export const StyledContainer = styled.div`
  
`

export const Title = styled.div`

`

export const SubTitle = styled.div`
  color: ${(props: IPropTheme) => props.theme.colors.grey};
`

export const Tab = styled.div`
  padding:5px;
  font-size:10px;
  min-width:25px;
`
