import { Button, Modal } from "antd";
import React, { FC } from "react";
import BakIcon from "../../../SvgIcons/icon-back";
import IconShutDown from "../../../SvgIcons/icon-shutdown";
import {
  BackIcon,
  Logo,
  LogoutMessageStyled,
  StyledContainer,
  Title,
} from "./styled";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Props } from "./types";
import connected from "./connects";
const { confirm } = Modal;

const MainHeader: FC<Props> = ({ 
    
    title, 
    back,
    logoutAction, 
    letfIcon

}) => {
  const backAction = () => {
    window.history.go(-1);
  };
  const _actionLogoutHandler = () => {
    console.log("yeas i wants");
  };
  const _logoutHandler = () => {
    console.log("log out bye bye hahha");
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <LogoutMessageStyled>هل تريد تسجيل الخروج !</LogoutMessageStyled>
      ),
      onOk() {
        logoutAction();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <StyledContainer>
      <BackIcon onClick={!letfIcon ? backAction : () => {}}>
        {" "}
        {letfIcon ? letfIcon : back && <BakIcon />}
      </BackIcon>
      <Title>{title}</Title>
      <Logo onClick={_logoutHandler}>
        <IconShutDown />
      </Logo>
    </StyledContainer>
  );
};

export default connected(MainHeader);
