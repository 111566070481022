import { IVisitsSchema } from './types'

export const VisitsSchema: IVisitsSchema | any = {
  City: {
    type: 'select',
    label: 'المحافظه',
    options: [],
    placeholder: 'المحافظه',
    rules: [
      {
        required: true,
      },
    ],
  },
  State: {
    type: 'select',
    label: 'المنطقة',
    options: [],
    placeholder: 'المنطقة',
    rules: [
      {
        required: true,
      },
    ],
  },
  Team: {
    type: 'select',
    label: 'الفوج او الفرقه',
    options: [],
    placeholder: 'الفوج او الفرقه',
    rules: [
      {
        required: true,
      },
    ],
  },
  TargetCategory: {
    type: 'select',
    label: ' الفئه',
    options: [
      {
        value: 0,
        label: 'براعم',
      },
      {
        value: 1,
        label: 'براعم  واشبال',
      },
      {
        value: 2,
        label: 'اشبال',
      },
      {
        value: 3,
        label: 'كشافه',
      },
      {
        value: 4,
        label: 'كشافه و جواله',
      },
      {
        value: 5,
        label: 'كشافه و جواله',
      },
    ],
    placeholder: 'الفئة',
    rules: [
      {
        required: true,
      },
    ],
  },

  Category: {
    type: 'select',
    label: 'جنس الفئه',
    options: [
      {
        value: 0,
        label: 'اخوة',
      },
      {
        value: 1,
        label: 'اخوات',
      },
    ],
    placeholder: 'الفئة',
    rules: [
      {
        required: true,
      },
    ],
  },
  Location: {
    type: 'text',
    label: 'الموقع',
    options: undefined,
    placeholder: 'الموقع',
    rules: [
      {
        required: true,
      },
    ],
  },
  Date: {
    type: 'date',
    label: 'تاريخ الزياره',
    options: undefined,
    placeholder: 'تاريخ الزياره',
    rules: [
      {
        required: true,
      },
    ],
  },
  Reason: {
    type: 'area',
    label: 'المستهدف من الزيارة',
    options: undefined,
    placeholder: 'المستهدف من الزيارة',
    rules: [
      {
        required: true,
      },
    ],
  },
  //  DONE add listing of point on typeing enter on textarea
  // the flow
  // when user jotdown the idea
  // will parse the text and show list for every \n or any other charachter
  // set new value at post
  Disscussion: {
    type: 'area',
    label: 'النقاط التي تم طرحها خلال الزيارة',
    options: undefined,
    placeholder: 'النقاط التي تم طرحها خلال الزيارة',
    rules: [
      {
        required: true,
      },
    ],
  },
  PositiveSides: {
    type: 'area',
    label: 'الايجابيات',
    options: undefined,
    placeholder: 'الايجابيات',
    rules: [
      {
        required: true,
      },
    ],
  },
  Notes: {
    type: 'area',
    label: 'ملاحظات',
    options: undefined,
    placeholder: 'ملاحظات',
    rules: [
      {
        required: true,
      },
    ],
  },
}


export const POIINTS_KEYS:any = {
  PositiveSides:"PositiveSides",
  Disscussion:"Disscussion",
  Reason: "Reason"
}