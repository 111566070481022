import { LStorage } from './../../Config/singleton';
import { CHECK_AUTH_ENDPOINT } from './../../Config/ApiEndpoints';
import { AxiosError } from 'axios'
import { axiosInstance } from '../../Config/api'
import { LOGIN_ENDPOINT } from '../../Config/ApiEndpoints'

export const loginApi: any = async (params: any) => {
  try {
    const response = await axiosInstance.post(LOGIN_ENDPOINT, params)

    return response
  } catch (err) {
    return err
  }
}

export const authCheckApi: any = () => {
  return axiosInstance.get(CHECK_AUTH_ENDPOINT)
  .then(res => res)
  .catch(error=> error)
}
