import { all, fork } from "redux-saga/effects";
import generalSaga from "../store/general/saga";
import kashafaSaga from "../store/Kashafa/saga";
import VisitsSaga from "../store/Visits/saga";
import JalasatSaga from "../store/Jalasat/saga";
import ActivitySaga from "../store/Activity/saga";
import EstmaraSaga from "../store/Estmara/saga"
import HistorySaga from "../store/History/saga"


export function* rootSaga() {
  yield all([fork(generalSaga)]);
  yield all([fork(kashafaSaga)]);
  yield all([fork(JalasatSaga)]);
  yield all([fork(VisitsSaga)]);
  yield all([fork(ActivitySaga)]);
  yield all([fork(EstmaraSaga)]);
  yield all([fork(HistorySaga)]);

}