import { addKashafDataSelector, addKashafLoadingSelector, addKashafErrorSelector } from './../../store/Kashafa/selectors';
import { addKashafAction, addKashafResetAction } from './../../store/Kashafa/actions';
import { connect } from "react-redux"

const mapDispatchToProps = {
    addKashafAction,
    addKashafResetAction
}


const mapStateToProps = (state:any) => {
    const kashaf = state.kashaf
    console.log("kashasf",kashaf)
    return {
        data:addKashafDataSelector(kashaf),
        loading:addKashafLoadingSelector(kashaf),
        error:addKashafErrorSelector(kashaf),  
    }


}
  
const connected = connect(mapStateToProps, mapDispatchToProps)
export default  connected