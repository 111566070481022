export const LOGIN_ENDPOINT = "User/Login"
export const CHECK_AUTH_ENDPOINT = "System/CheckAuth"

export const ADD_KASHAF_ENPOIND =""
export const ADD_JALASAT =""
export const ADD_VISIT_ENDPOINT =""
export const ADD_ACTIVITY_ENDPOINT =""  



export const GET_MUHAFAZAT_ENDPOINT = "Estmara/GetAllMuhafazat"


export const GET_MANATIK_ENDPOINT = "Estmara/GetAllMuhafazat"


export const GET_FARAQ_ENDPOINT = "Estmara/GetAllFaraqByNuhafaza"