import React, { FC } from "react";
import { ButtonStyled } from "./styled";
import { Props } from "./types";

const ButtonComponent: FC<Props> = ({ clickButton, title, wait }) => {
  const _handleClick = () => {
    if (!wait) {
      clickButton();
    }
  };

  return (
    <ButtonStyled onClick={_handleClick} wait={wait}>
      {title}
    </ButtonStyled>
  );
};

export default ButtonComponent;
