import React from "react";
import Routes from "./Routes";
import { AppStyled } from "./theme/styled";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";


function App() {

  return (
    <AppStyled>
        <Routes />
    </AppStyled>
  );
}

export default App;
