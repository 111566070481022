import { issetResponseResult } from './../global/respones';
import { addActivityFailAction, addActivityStartAction, addActivitySuccessAction } from './actions';
import { NO_DATA } from './../../Common/errors';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_VISIT, ADD_ACTIVITY } from './../global/actionTypes';
import {  addActivityApi } from './api';
import { ISagaData } from '../global/types';
import { issetResponse } from '../global/respones';
import { SERVER_ERROR } from '../../Common/errors';
import { AxiosResponse } from 'axios';



function* addActivitySaga(data:ISagaData) {
    try {
        yield put(addActivityStartAction())
        


      const response:AxiosResponse = yield call(addActivityApi,data.params);

      if(issetResponseResult(response)){
        yield put(addActivitySuccessAction(response.data.result))
        
      }else{

        yield put(addActivityFailAction(NO_DATA));

        }

    } catch (e) {
      yield put(addActivityFailAction(SERVER_ERROR));
    }
  }




 function* ActivitySaga() {
    yield all([takeLatest(ADD_ACTIVITY, addActivitySaga)]);


  }

  export default ActivitySaga