import { GET_HISTORY_SUCCESS, GET_HISTORY_RESET, GET_HISTORY_START, GET_HISTORY_FAIL } from './../global/actionTypes';
import produce, { Draft } from 'immer';
import { IHISTORYtype } from './types';

const initialState: IHISTORYtype = {
    data:null,
    loading:false,
    error:null,

  };



  const getHistoryStartReducers = (state:IHISTORYtype)=> {

    return  produce(state,(draft:Draft< IHISTORYtype >) => {
          draft.data=null;
          draft.error = null;
          draft.loading = true
      
      })
  
}



const getHistorySuccessReducers = (state:IHISTORYtype ,action:any)=> {

  return  produce(state,(draft:Draft< IHISTORYtype >) => {
        draft.error = null;
        draft.loading = false;
        draft.data = action.payload
    
    })

}

const getHistoryFailReducers = (state:IHISTORYtype ,action:any)=> {

  return  produce(state,(draft:Draft< IHISTORYtype >) => {
        draft.error = action.error
        draft.loading = false;
    
    })

}

const getHistoryResetReducers = (state:IHISTORYtype )=> {

  return  produce(state,(draft:Draft< IHISTORYtype >) => {
        draft.error = null
        draft.loading = false;
        draft.data = null
    
    })

}


 function getHistoryReducer(state = initialState, action: any) {
    switch (action.type) {
     case GET_HISTORY_START : return getHistoryStartReducers(state);
     case GET_HISTORY_SUCCESS: return getHistorySuccessReducers(state,action);
     case GET_HISTORY_FAIL: return getHistoryFailReducers(state,action)
     case GET_HISTORY_RESET: return getHistoryResetReducers(state)

      default: return state
    }
  };
  

  export default getHistoryReducer

