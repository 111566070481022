import React from "react";

function BakIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path fill="#fff" fillOpacity="0.01" d="M0 0H48V48H0z"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M44 40.836c-4.893-5.973-9.238-9.362-13.036-10.168-3.797-.805-7.412-.927-10.846-.365V41L4 23.545 20.118 7v10.167c6.349.05 11.746 2.328 16.192 6.833 4.445 4.505 7.009 10.117 7.69 16.836z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BakIcon;